import React from 'react';
import LoadingBar from '../LoadingBar';

interface Props {
  text: string;
}

export default function LoadingDialogWithText(props: Props) {
  return (
    <div className="flex text-center min-h-screen m-auto justify-center items-center">
      <div className="m-auto">
        <p className="text-xl p-4 m-auto">{props.text}</p>
        <LoadingBar isLoading={true} />
      </div>
    </div>
  );
}
