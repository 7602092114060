import React from 'react';

export const AllInOnePlaceAnimation = () => {
  return (
    <div className="w-11/12 md:w-2/3 bg-white shadow-2xl rounded my-6 overflow-x-auto m-auto ">
      <table className="w-full table-auto">
        <thead>
          <tr className="bg-gray-200 text-gray-600 text-xs md:text-sm leading-normal">
            <th className="p-3 md:py-3 md:px-6 text-left">Name</th>
            <th className="p-3 md:py-3 md:px-6 text-center">Url</th>
            <th className="p-3 md:py-3 md:px-6 text-center">Status</th>
          </tr>
        </thead>
        <tbody className="text-gray-600 text-xs md:text-sm font-light">
          <tr className="border-b border-gray-200">
            <td className="p-3 md:py-3 md:px-6 text-left">
              <div className="flex items-center">
                <span>my cool site</span>
              </div>
            </td>
            <td className="p-3 md:py-3 md:px-6 text-center flex">
              example.com
            </td>
            <td className="p-3 md:py-3 md:px-6 text-center">
              <span className="bg-green-600 nm-all-in-one-place-active px-2 py-1 md:py-1 md:px-3 rounded-full text-xs text-white">
                Active
              </span>
            </td>
          </tr>
          <tr className="border-b border-gray-200">
            <td className="p-3 md:py-3 md:px-6 text-left">
              <div className="flex items-center">
                <span>just added site</span>
              </div>
            </td>
            <td className="p-3 md:py-3 md:px-6 text-center flex">
              pending.com
            </td>
            <td className="p-3 md:py-3 md:px-6 text-center">
              <span className="bg-yellow-600 nm-all-in-one-place-pending px-2 py-1 md:py-1 md:px-3 rounded-full text-xs text-white">
                Pending
              </span>
            </td>
          </tr>
          <tr className="border-b border-gray-200">
            <td className="p-3 md:py-3 md:px-6 text-left">
              <div className="flex items-center">
                <span>not working </span>
              </div>
            </td>
            <td className="p-3 md:py-3 md:px-6 text-center flex">404.com</td>
            <td className="p-3 md:py-3 md:px-6 text-center">
              <span className="bg-red-600 nm-all-in-one-place-down px-2 py-1 md:py-1 md:px-3 rounded-full text-xs text-white">
                Down
              </span>
            </td>
          </tr>
          <tr className="border-b border-gray-200">
            <td className="p-3 md:py-3 md:px-6 text-left">
              <div className="flex items-center">
                <span>news site</span>
              </div>
            </td>
            <td className="p-3 md:py-3 md:px-6 text-center flex">
              news-site.com
            </td>
            <td className="p-3 md:py-3 md:px-6 text-center">
              <span className="bg-green-600 nm-all-in-one-place-news px-2 py-1 md:py-1 md:px-3 rounded-full text-xs text-white">
                Active
              </span>
            </td>
          </tr>
          <tr className="border-b border-gray-200">
            <td className="p-3 md:py-3 md:px-6 text-left">
              <div className="flex items-center">
                <span>not working again</span>
              </div>
            </td>
            <td className="p-3 md:py-3 md:px-6 text-center flex">
              more-404.com
            </td>
            <td className="p-3 md:py-3 md:px-6 text-center">
              <span className="bg-red-600 nm-all-in-one-place-down2 px-2 py-1 md:py-1 md:px-3 rounded-full text-xs text-white">
                Down
              </span>
            </td>
          </tr>
          <tr className="border-b border-gray-200">
            <td className="p-3 md:py-3 md:px-6 text-left">
              <div className="flex items-center">
                <span>...</span>
              </div>
            </td>
            <td className="p-3 md:py-3 md:px-6 text-center flex">...</td>
            <td className="p-3 md:py-3 md:px-6 text-center">...</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const contentAllInOnePlace = () => {
  return (
    <>
      <p>Replace all the different update feeds with Notify Me.</p>
      <p className="mt-8">
        Organize all your sites and reduce the unnecessary clutter from random
        newsletters.
      </p>
    </>
  );
};
