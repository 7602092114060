/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import * as api from '../../api';
import HelmetComponent from '../../components/HelmetComponent';
import * as errors from '../../errors';
import { useNavigate, useParams } from 'react-router';
import LoadingBar from '../../components/LoadingBar';
import { EventType, registerEvent } from '../../plausible';
import useApiCall from '../../hooks/UseApiCall';
import useQueryParam from '../../hooks/UseQueryParam';
import LoadingDialogWithText from '../../components/LoadingDialogWithText';

export default function ConfirmRegistration() {
  const [errorMessage, setErrorMessage] = useState('');
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(true);
  const queryParam = useQueryParam();
  const navigate = useNavigate();
  const { token } = useParams();
  const apiCall = useApiCall(setWaitingForServerResponse);

  useEffect(() => {
    (async () => {
      const response = await apiCall(
        await api.verifyRegistrationToken(token as any),
        'Registration successful! You can now log in.',
      );

      if (response.error) {
        setErrorMessage(errors.INVALID_CONFIRMATION_LINK);
        return;
      }
      registerEvent(EventType.SIGNUP_VERIFICATION);
      const packageName = queryParam('package');
      const packageQuery =
        packageName !== ''
          ? `?next=/add_package?package=${packageName}`
          : '?next=/add_new_site';
      navigate(`/login${packageQuery}`);
    })();
  }, []);

  if (waitingForServerResponse) {
    return (
      <LoadingDialogWithText text="Give us a few moments to confirm your registration..." />
    );
  }

  return (
    <div className="flex text-center min-h-screen m-auto justify-center items-center">
      <HelmetComponent
        title="Confirm Registration | Notify Me"
        description="Confirms that user has been verified."
      />
      {/* Only way we will render this is if the error happened */}
      <div className="block p-6">
        <p className="">{errorMessage}</p>
        <button
          className="btn btn-primary btn-wide mt-4"
          onClick={() => navigate('/signup')}
        >
          Go to Sign Up Page
        </button>
      </div>
    </div>
  );
}
