import React, { useState, useEffect } from 'react';
import * as api from '../../api';
import HelmetComponent from '../../components/HelmetComponent';
import * as errors from '../../errors';
import { useNavigate } from 'react-router';
import LoadingBar from '../../components/LoadingBar';
import { EventType, registerEvent } from '../../plausible';
import useApiCall from '../../hooks/UseApiCall';
import useQueryParam from '../../hooks/UseQueryParam';
import { SiteFromPackage } from '../../types';
import { AddPackageResponse } from '../../api/types';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import { delay } from '../../utils';

interface SiteFromPackageWithAdditonalInfo extends SiteFromPackage {
  id: string;
  isSubmitted: boolean;
}

export default function AddPackage() {
  const [errorMessage, setErrorMessage] = useState('');
  const [sitesFromPackage, setSitesFromPackage] = useState<
    Array<SiteFromPackageWithAdditonalInfo>
  >([]);
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(true);
  const queryParam = useQueryParam();
  const navigate = useNavigate();
  const apiCall = useApiCall(setWaitingForServerResponse);

  useEffect(() => {
    (async () => {
      const packageName = queryParam('package');
      if (packageName === '') {
        return navigate('/404');
      }
      const response = await apiCall(api.getPackage(packageName), undefined);

      if (response.error) {
        setErrorMessage(errors.INVALID_PACKAGE);
        return navigate('/404');
      }

      const responseData = response.data as AddPackageResponse;
      const sites: Array<SiteFromPackageWithAdditonalInfo> = [];

      responseData.sites.forEach((site) => {
        sites.push({
          name: site.name,
          url: site.url,
          keywords: site.keywords,
          isSubmitted: true,
          id: uuidv4(),
        });
      });

      setSitesFromPackage(sites);
      registerEvent(EventType.ADD_PACKAGE);
    })();
  }, []);

  if (waitingForServerResponse && sitesFromPackage.length !== 0) {
    return (
      <div className="nm-default-page-small-content">
        <div className="">
          <p className="text-xl lg:text-3xl p-4">
            Give us a few moments to add your sites...
          </p>
          <LoadingBar isLoading={true} />
        </div>
      </div>
    );
  }

  if (waitingForServerResponse) {
    return (
      <div className="nm-default-page-small-content">
        <div className="">
          <p className="text-xl lg:text-3xl p-4">
            Give us a few moments to find your package...
          </p>
          <LoadingBar isLoading={true} />
        </div>
      </div>
    );
  }

  if (errorMessage !== '') {
    <div className="nm-default-page-small-content">
      <HelmetComponent
        title="Add Package | Notify Me"
        description="Adds new package with already populated sites for tracking."
      />
      {/* Only way we will render this is if the error happened */}
      <div className="block p-6">
        <p className="nm-default-page-heading-white-text">{errorMessage}</p>
        <button
          className="btn btn-primary btn-wide mt-4"
          onClick={() => navigate('/signup')}
        >
          Go to Sign Up Page
        </button>
      </div>
    </div>;
  }

  const switchIsSubmitted = (idToSwitch: string) => {
    const updatedList = sitesFromPackage.map((site) => {
      if (site.id !== idToSwitch) {
        return site;
      }
      site.isSubmitted = !site.isSubmitted;
      return site;
    });
    setSitesFromPackage(updatedList);
  };

  const displaySites = () => {
    return (
      <>
        {sitesFromPackage.map((site) => (
          <div
            key={site.id}
            className="bg-main-backgroundFooter p-4 rounded-xl text-left border-2 border-main-letters mx-auto my-4 relative z-10"
          >
            {site.isSubmitted ? (
              <div onClick={() => switchIsSubmitted(site.id)}>
                <p className="absolute top-1 right-1 text-sm btn btn-outline btn-error btn-sm z-10">
                  Remove from list
                </p>
              </div>
            ) : (
              <div onClick={() => switchIsSubmitted(site.id)}>
                <p className="absolute top-1 right-1 text-sm btn btn-outline btn-success btn-sm z-10">
                  Add to list
                </p>
              </div>
            )}
            <div className={site.isSubmitted ? '' : 'opacity-50'}>
              <p className="pb-2">
                <span className="font-bold">name: </span>
                <span>{site.name}</span>
              </p>
              <p className="pb-2">
                <span className="font-bold">url: </span>
                <span className=""> {site.url}</span>
              </p>
              {site.keywords == '' ? (
                ''
              ) : (
                <p className="pb-2">
                  <span className="font-bold">keywords: </span>
                  <span className="italic">{site.keywords}</span>
                </p>
              )}
            </div>
          </div>
        ))}
      </>
    );
  };

  const onConfirm = async () => {
    const sitesForSubmission = sitesFromPackage.filter(
      (site) => site.isSubmitted,
    );
    if (sitesForSubmission.length == 0) {
      toast.error('No sites have been selected.');
      return;
    }

    //? Done like this instead of forEach cuz I honestly cannot be bothered to deal with promises inside of the forEach
    for (const site of sitesForSubmission) {
      const newSite = {
        id: site.id,
        siteName: site.name,
        siteUrl: site.url,
        keywords: site.keywords,
      };
      await apiCall(
        api.addSite(newSite),
        `Site ${site.name} added! We'll send you an email as soon as we access the site.`,
      );
      setWaitingForServerResponse(true);
      await delay(300);
    }

    await delay(300);
    return navigate('/overview');
  };

  return (
    <div className="">
      <HelmetComponent
        title="Add Package | Notify Me"
        description="Adds new package with already populated sites for tracking."
      />
      <div className="p-6 max-w-xl m-auto">
        <p className="nm-default-page-heading">Following sites will be added</p>
        <div className="mx-auto mt-8">{displaySites()}</div>
        <div className="">
          <div className="mt-4 p-2 m-auto">
            <button
              className="btn btn-error btn-block mt-4"
              onClick={() => navigate('/overview')}
            >
              Decline
            </button>
          </div>
          <div className="p-2 m-auto">
            <button
              className="btn btn-success btn-block mt-4"
              onClick={onConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
