import React, { MouseEventHandler } from 'react';
import { LinkButton } from '../../../components/LinkButton';

interface Props {
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export default function VerifyAccountDialog(props: Props) {
  return (
    <div className="text-xs text-center">
      <p className="mb-2">Please verify your account.</p>
      <p>
        {"If you haven't received confirmation email "}
        <LinkButton onClick={props.onClick} label="click here" /> to resend it.
      </p>
    </div>
  );
}
