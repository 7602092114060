import React from 'react';

interface DeletePopupProps {
  setIsOpen: Function;
  deleteSite: Function;
}

export default function DeletePopup(props: DeletePopupProps) {
  return (
    <div className="bg-main-backgroundFooter border-2 border-main-letters p-10 text-white rounded-xl max-w-xs m-auto">
      <div className="text-left">
        <p className="mb-4 m-auto text-xl ">
          Are you sure that you want to delete this site?
        </p>
        <div className="flex max-w-2xl">
          <button
            className="btn btn-primary font-bold m-auto lg:m-4"
            onClick={() => props.setIsOpen(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary btn-error font-bold m-auto lg:m-4"
            onClick={() => props.deleteSite()}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}
