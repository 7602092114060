import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import * as api from '../../api';
import toast from 'react-hot-toast';

const CheckoutSuccess = 'Checkout.Success';
const PaymentMethodUpdateUpdated = 'PaymentMethodUpdate.Updated';

export default function LemonSqueezy({ children }) {
  useEffect(() => {
    document.querySelectorAll('.lemonsqueezy-button').forEach((i) => {
      i.classList.add('pointer-events-none');
    });
    var script = document.createElement('script');
    script.onload = () => {
      setTimeout(() => {
        window.createLemonSqueezy();
        document.querySelectorAll('.lemonsqueezy-button').forEach((i) => {
          i.classList.remove('pointer-events-none');
        });
        window.LemonSqueezy.Setup({
          eventHandler: async (data) => {
            if (data.event == CheckoutSuccess) {
              const orderId = data.data.order.data.id;
              const res = await api.registerOrder(orderId);
              if (res.ok) {
                toast.success('Payment successful');
                return window.location.reload();
              }
              Sentry.captureException(res, 'Payment failed');
              return toast.error('Payment failed');
            }
            if (data.event == PaymentMethodUpdateUpdated) {
              toast.success('Payment method updated');
              return window.location.reload();
            }
          },
        });
      }, 1000);
    };
    script.src = 'https://assets.lemonsqueezy.com/lemon.js';
    document.head.appendChild(script);
  }, []);
  return <>{children}</>;
}
