import React from 'react';
import { SiteData } from '../types';
import Actions from './Actions';
import {
  determineCompleteUrl,
  determineLastAccessDate,
  DetermineSiteStatus,
} from './utils';
import { BiLinkExternal } from 'react-icons/bi';

interface Props {
  sites: Array<SiteData>;
  onDelete: Function;
  onEdit: Function;
}

const generateOverviewForSingleSite = (
  site: SiteData,
  onDelete: Function,
  onEdit: Function,
) => {
  return (
    <div
      className="text-gray-600 py-3 px-6 my-3 bg-white rounded text-sm"
      key={site.id}
    >
      <div className="text-left">
        <p className="truncate ... flex">
          <b>Name:</b>{' '}
          <a
            className="underline ml-1 flex"
            href={determineCompleteUrl(site.siteUrl)}
            target="blank"
          >
            {site.siteName}
            <BiLinkExternal size={14} className="ml-0.5" />
          </a>{' '}
        </p>
      </div>
      <div className="text-left my-3">
        <p className="">
          <b>Last Access Date:</b>{' '}
          <span className="text-sm">
            {determineLastAccessDate(site.lastActiveDate)}
          </span>{' '}
        </p>
      </div>
      <div className="flex border-gray-600 border-t mt-3">
        <div className="w-1/2 mt-2">
          <DetermineSiteStatus site={site} />
        </div>
        <div className="w-1/2 mt-4">
          <Actions onDelete={onDelete} onEdit={onEdit} siteId={site.id} />
        </div>
      </div>
    </div>
  );
};

export default function OverviewMobile(props: Props) {
  return (
    <div className="sm:hidden rounded my-6 overflow-x-auto text-gray-600">
      {props.sites.map((site) => {
        return generateOverviewForSingleSite(
          site,
          props.onDelete,
          props.onEdit,
        );
      })}
    </div>
  );
}
