import { ACCESS_TOKEN } from './types';

export const setAccessToken = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN, token);
};

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const clearStorage = () => {
  localStorage.clear();
};
