import React from 'react';
import { TrackSitesQuicklyAndEasilyAnimation } from '../../animations/TrackSitesQuicklyAndEasily';

export default function TrackSitesQuicklyAndEasily() {
  return (
    <div className="mt-4 p-4 m-auto max-w-5xl text-main-background">
      <div>
        <div className="my-8">
          <p className="text-center text-3xl lg:text-5xl xl:text-6xl font-heading p-4 mt-8">
            Track Sites Quickly and Easily
          </p>
          <p className="text-xl xl:text-2xl text-center mt-8">
            Notify Me only requires a site URL to start tracking.
          </p>
        </div>
        <div className="flex">
          <div className="max-w-3xl m-auto mt-8 mb-16">
            <TrackSitesQuicklyAndEasilyAnimation />
          </div>
        </div>
      </div>
    </div>
  );
}
