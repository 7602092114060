import React from 'react';
import logoImg from '../../../img/icons/nm_logo/main_logo.svg';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

export default function Navbar() {
  const navigate = useNavigate();
  return (
    <nav className="navbar bg-main-backgroundFooter">
      <div className="navbar-start">
        <div className="w-16 cursor-pointer" onClick={() => navigate('/')}>
          <img alt="Notify Me logo" src={logoImg} />
        </div>
        <p className="invisible lg:visible font-heading text-main-letters text-2xl">
          <Link to="/"> Notify Me</Link>
        </p>
      </div>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1">
          <li className="btn btn-ghost hover:underline">
            <Link to="/pricing">Pricing</Link>
          </li>
          <li className="btn btn-ghost hover:underline">
            <Link to="/earn" className="">
              Affiliates
            </Link>
          </li>
          <li className="btn btn-ghost hover:underline">
            <Link to="/how_it_works">How it Works</Link>
          </li>
          <li className="btn btn-ghost hover:underline">
            <Link to="/blog">Blog</Link>
          </li>
        </ul>
      </div>
      <div className="navbar-end">
        <div className="dropdown dropdown-end">
          <div
            tabIndex={0}
            role="button"
            className="btn btn-ghost lg:hidden bg-white text-main-backgroundFooter"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </div>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-main-backgroundFooter rounded-box w-52"
          >
            <li className="mt-2">
              <Link to="/pricing">Pricing</Link>
            </li>
            <li className="mt-2">
              <Link to="/login">Log In</Link>
            </li>
            <li className="mt-2">
              <Link to="/signup">Sign Up</Link>
            </li>
            <li className="mt-2">
              <Link to="/how_it_works">How it Works</Link>
            </li>
            <li className="mt-2">
              <Link to="/blog">Blog</Link>
            </li>
            <li className="mt-2">
              <Link to="/earn">Affiliates</Link>
            </li>
          </ul>
        </div>
        <div className="hidden lg:flex">
          <ul className="menu menu-horizontal px-1">
            <li className="btn btn-ghost hover:underline">
              <Link to="/login">Log In</Link>
            </li>
            <li className="btn btn-primary">
              <Link to="/signup">Sign Up - It&apos;s free</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
