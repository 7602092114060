import React, { useEffect, useState } from 'react';
import * as api from '../../api';
import { useParams } from 'react-router';
import LoadingBar from '../../components/LoadingBar';
import { useAuth } from '../../context/AuthContext';
import HelmetComponent from '../../components/HelmetComponent';
import { useFormik } from 'formik';
import { confirmResetPasswordValidationSchema } from './validation';
import useApiCall from '../../hooks/UseApiCall';
import LoadingDialogWithText from '../../components/LoadingDialogWithText';
import InputField from '../../components/InputField';

export default function ConfirmResetPassword() {
  const [shouldRender, setShouldRender] = useState(false);
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(false);

  const { token } = useParams();
  const authContext = useAuth();

  const apiCall = useApiCall(setWaitingForServerResponse);
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: confirmResetPasswordValidationSchema,
    onSubmit: async (values) => {
      onSubmit(values.password, token as string);
    },
  });

  const verifyResetToken = async () => {
    const response = await api.verifyResetPasswordToken(token as any);
    return response.ok;
  };

  useEffect(() => {
    (async () => {
      const tokenVerified = await verifyResetToken();
      if (!tokenVerified) {
        authContext.logoutUser('/404', false);
        return;
      }
      setShouldRender(true);
    })();
  }, []);

  const onSubmit = async (password: string, token: string) => {
    const response = await apiCall(
      api.saveNewPassword(password, token),
      'Password reset successful! You can now log in with your new password.',
    );
    if (response.error) {
      return;
    }

    authContext.logoutUser('/login', false);
  };

  if (!shouldRender) {
    return (
      <LoadingDialogWithText text="Give us a few moments to reset your password..." />
    );
  }

  return (
    <div className="h-[80%]">
      <HelmetComponent
        title="Confirm Password Reset | Notify Me"
        description="Confirms that user really initialized password reset."
      />
      <div className="flex lg:min-h-screen justify-center items-center">
        <form
          className="bg-main-footerLinks bg-opacity-40 p-8 border rounded-xl border-main-letters max-w-sm w-full"
          onSubmit={formik.handleSubmit}
        >
          <div>
            <h1 className="my-4 lg:my-8 font-bold font-heading text-4xl text-main-letters text-center">
              Reset Password
            </h1>
          </div>
          <InputField
            label="new password"
            id="password"
            type="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : undefined
            }
          />
          <InputField
            label="confirm new password"
            id="confirmPassword"
            type="password"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirmPassword && formik.errors.confirmPassword
                ? formik.errors.confirmPassword
                : undefined
            }
          />
          <button className="btn btn-block btn-primary">Reset Password</button>
          <div className="text-center">
            <div className="mt-4">
              <LoadingBar isLoading={waitingForServerResponse} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
