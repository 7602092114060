import React from 'react';
interface Props {
  isLoading: boolean;
}

export default function LoadingBar(props: Props) {
  if (!props.isLoading) {
    return <></>;
  }

  return <span className="loading loading-lg"></span>;
}
