import React from 'react';

export default function MaintenancePage() {
  return (
    <div className="w-full text-center m-auto">
      <h1>
        We are currently under maintenance.
        <br /> Please come back later.
      </h1>
    </div>
  );
}
