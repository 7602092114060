import React, { MouseEventHandler } from 'react';

interface Props {
  onClick: MouseEventHandler<HTMLButtonElement>;
  label: string;
  className?: string;
}

export function LinkButton(props: Props) {
  return (
    <span>
      <button
        type="button"
        onClick={props.onClick}
        className={
          'bg-transparent border-none cursor-pointer underline text-white inline hover:no-underline focus:no-underline ' +
          props.className
        }
      >
        {props.label}
      </button>
    </span>
  );
}

export default LinkButton;
