import React from 'react';
import { getEmailFromToken } from '../../../auth';

export default function EmailPopup() {
  return (
    <div className="bg-main-backgroundFooter border-2 border-main-letters p-10 text-white rounded-xl max-w-md m-auto">
      <div className="text-left">
        <p className="mb-4 m-auto text-xl">Email notifications</p>
        <p className="mb-4 m-auto text-sm">
          This is the default notification channel which is always active.
        </p>
        <p className="mb-4 m-auto text-sm">
          Your email: <b>{getEmailFromToken()}</b>
        </p>
      </div>
    </div>
  );
}
