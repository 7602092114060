export const PROBLEM_ON_OUR_SIDE =
  'There has been a problem on our side, please try again later.';

export const NOT_VERIFIED = 'Please verify your account.';

export const INVALID_PASSWORD_FORMAT =
  'Password must be between 8 and 30 characters long.';

export const INVALID_EMAIL_FORMAT = 'Invalid email or email format.';

export const PASSWORDS_DONT_MATCH = "Passwords don't match.";

export const RESET_PASSWORD_FAILED =
  'Reseting the password has failed, please try again.';

export const INVALID_SITE_NAME = 'Site name cannot be empty.';

export const INVALID_URL_FORMAT = 'Invalid URL format.';

export const EMPTY_INPUT_FIELD = 'You must write something in the input field.';

export const INVALID_CONFIRMATION_LINK = 'Confirmation link is invalid.';

export const REQUIRED_FIELD = 'This field is required.';

export const GOOGLE_OAUTH_ERROR =
  'There has been a problem with Google Login.\n Please try again later or try a different login method.';

export const INVALID_DISCORD_WEBHOOK_FORMAT = 'Invalid webhook format.';

export const SESSION_EXPIRED = 'Your session has expired, please log in again.';

export const INVALID_PACKAGE = 'Invalid package was provided.';
