import React from 'react';
import CardWithText from '../../components/CardWithText';

export default function UseCaseSection() {
  return (
    <>
      <div className="text-main-background m-auto max-w-8xl">
        <div className="text-center">
          <p className="text-3xl lg:text-5xl xl:text-6xl font-heading p-4 mt-8">
            How People Are Using Notify Me?
          </p>
          <p className="text-xl xl:text-2xl text-center">
            Improve your workflow with automated alerts whenever a website
            changes.
          </p>
        </div>
        <div className="mx-auto md:grid md:grid-cols-5 text-white p-8">
          <CardWithText
            header="Enterpreneurs."
            text="Stay ahead of the curve and always have the quickest reaction to competitor changes."
          />
          <CardWithText
            header="Apartment Searching."
            text={
              'Track aparment listings and catch new deals as soon as they appear.'
            }
          />
          <CardWithText
            header="New Jobs Alerts."
            text={
              "Don't miss out on new jobs you are interested in. Be first to apply for a job."
            }
          />
          <CardWithText
            header="Website owners."
            text="Monitor your site layout for unintended changes and ensure that your site is always up and running."
          />
          <CardWithText
            header="Students."
            text={
              'Stop constantly checking professor and course sites and receive updates directly in your inbox.'
            }
          />
          <CardWithText
            header="Tracking Grants."
            text={
              'Effortlessly monitor government and public procurement sites to stay up-to-date on the latest regulatory changes and public tenders.'
            }
          />
          <CardWithText
            header="Software Engineers."
            text={
              'Track whenever a package you are using is updated. Be prepared for breaking changes.'
            }
          />

          <CardWithText
            header="Journalists."
            text="Keep track of the latest news and be aware of subtle changes in articles that might otherwise go unnoticed."
          />
          <CardWithText
            header="Competitive Advantage."
            text={
              'Keep up with your compettion and quickly react to changes they are making.'
            }
          />
          <CardWithText
            header="In Stock Availability."
            text={
              'Track items you want to buy. Be first to get notified whenever some item is up for sale or in stock again.'
            }
          />
        </div>
      </div>
    </>
  );
}
