import React, { useState, useEffect } from 'react';
import * as api from '../../api';
import HelmetComponent from '../../components/HelmetComponent';
import { useNavigate } from 'react-router';
import LoadingBar from '../../components/LoadingBar';
import { EventType, registerEvent } from '../../plausible';
import useApiCall from '../../hooks/UseApiCall';
import useQueryParam from '../../hooks/UseQueryParam';

export default function ChangeFeedback() {
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(true);

  const navigate = useNavigate();
  const queryParam = useQueryParam();
  const apiCall = useApiCall(setWaitingForServerResponse);

  useEffect(() => {
    (async () => {
      const oldDiffUrl = queryParam('old_diff_url');
      const newDiffUrl = queryParam('new_diff_url');
      const siteUrl = queryParam('site_url');
      const feedbackType = queryParam('feedback_type');
      const userEmail = queryParam('user_email');
      const viewInBrowserLink = queryParam('view_in_browser_link');

      const response = await apiCall(
        await api.changeFeedback(
          siteUrl,
          oldDiffUrl,
          newDiffUrl,
          feedbackType,
          userEmail,
          viewInBrowserLink,
        ),
        undefined,
      );

      if (response.error) {
        navigate('/login');
        return;
      }
      registerEvent(EventType.SEND_CHANGE_FEEDBACK);
    })();
  }, []);

  if (waitingForServerResponse) {
    return (
      <div className="nm-default-page-small-content">
        <div className="">
          <p className="text-xl lg:text-3xl p-4">Sending your feedback...</p>
          <LoadingBar isLoading={true} />
        </div>
      </div>
    );
  }

  return (
    <div className="nm-default-page-small-content">
      <HelmetComponent
        title="Change Feedback | Notify Me"
        description="Sends the user feedback about a specific change."
        canonicalLink="/change_feedback"
      />
      <div className="block p-6">
        <p className="nm-default-page-heading-white-text">
          Feedback sent successfully, thank you for your time!{' '}
        </p>
      </div>
    </div>
  );
}
