import React from 'react';
import { RoughNotation } from 'react-rough-notation';

interface Props {
  text: React.ReactNode;
  style?: 'highlight' | 'underline';
  color?: string;
  padding?: number;
}

export const RoughNotationWrapper = (props: Props) => {
  const style = props.style === undefined ? 'highlight' : props.style;

  return (
    <>
      <RoughNotation
        type={style}
        show={true}
        animate={false}
        multiline={true}
        strokeWidth={2}
        padding={props.padding !== undefined ? props.padding : 0}
        color={props.color !== undefined ? props.color : '#6EC6CA'}
      >
        {props.text}
      </RoughNotation>
    </>
  );
};
