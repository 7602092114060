import React from 'react';
import { AllInOnePlaceAnimation } from '../../animations/AllInOnePlace';

export default function AllInOnePlace() {
  return (
    <div className="mt-4 p-4 m-auto max-w-5xl">
      <div>
        <div className="my-8">
          <p className="text-center text-3xl lg:text-5xl xl:text-6xl font-heading p-4 mt-8">
            All In One Place
          </p>
          <p className="text-xl xl:text-2xl text-center mt-8">
            Replace all the different update feeds with Notify Me. Easily see
            site status and changes.
          </p>
        </div>
        <div className="my-16">
          <div className="max-w-4xl m-auto">
            <AllInOnePlaceAnimation />
          </div>
        </div>
      </div>
    </div>
  );
}
