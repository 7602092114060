import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEmailFromToken } from '../../auth';
import * as api from '../../api';
import { EventType, registerEvent } from '../../plausible';
import LoadingBar from '../../components/LoadingBar';
import useApiCall from '../../hooks/UseApiCall';

export default function MyAccount() {
  const navigate = useNavigate();
  const [resetPasswordClicked, setResetPasswordClicked] =
    useState<boolean>(false);
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(false);

  const apiCall = useApiCall(setWaitingForServerResponse);

  const onResetPassword = async () => {
    setResetPasswordClicked(true);
    await apiCall(
      api.resetPassword(getEmailFromToken()),
      'Reset email successfully sent. Please check your inbox.',
    );
    registerEvent(EventType.RESET_PASSWORD);
  };

  const generatePasswordResetRequestedMessage = () => {
    if (waitingForServerResponse) {
      return (
        <div className="pt-4">
          <LoadingBar isLoading={true} />
        </div>
      );
    }
    if (resetPasswordClicked) {
      return (
        <p className="text-sm text-main-footerLinks pt-4">
          Password reset requested.
        </p>
      );
    }
  };

  return (
    <>
      <p className="nm-default-page-heading">My Account</p>
      <div className="rounded-xl max-w-2xl mx-auto bg-main-backgroundFooter mt-8">
        <div className="p-6 m-auto">
          <p className="text-xl ">Email</p>
          <p className="text-sm  text-main-footerLinks pt-4">
            This is your email:{' '}
            <span className="text-white">{getEmailFromToken()}</span>
          </p>
          <p className="text-sm text-main-footerLinks pt-2">
            We will send you all relevant updates here.
          </p>
        </div>
      </div>
      <div className="rounded-xl max-w-2xl mx-auto bg-main-backgroundFooter mt-8">
        <div className="p-6 m-auto">
          <p className="text-xl">Password</p>
          <div>{generatePasswordResetRequestedMessage()}</div>
          <button
            className="btn btn-primary cursor-pointer mt-4"
            onClick={onResetPassword}
          >
            Reset your password
          </button>
        </div>
      </div>
      <div className="rounded-xl max-w-2xl mx-auto bg-main-backgroundFooter mt-8">
        <div className="p-6 m-auto">
          <p className="text-xl ">Delete your account</p>
          <p className="text-sm  text-main-footerLinks pt-4">
            Delete your account and all the data associated with it.
          </p>
          <button
            className="btn btn-error cursor-pointer mt-4"
            onClick={() => navigate('/delete_account')}
          >
            Delete account
          </button>
        </div>
      </div>
    </>
  );
}
