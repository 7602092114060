import { useLocation } from 'react-router-dom';

export default function useQueryParam() {
  const location = useLocation();

  return (paramName: string): string => {
    const param = new URLSearchParams(location.search).get(paramName);
    if (param === null) {
      return '';
    }
    return param;
  };
}
