import { ApiResponse, create } from 'apisauce';
import { TrackedSite } from '../types';
import {
  BillingInfoResponse,
  DiscordData,
  GoogleOAuthResponse,
  LoginResponse,
  ParsedSiteHistory,
} from './types';
import { getAccessToken } from '../auth';

let backendUrl = 'http://localhost:5007';

if (process.env.REACT_APP_ENV === 'staging') {
  backendUrl = 'https://backend.staging.cluster.notify-me.rs';
} else if (process.env.REACT_APP_ENV === 'production') {
  backendUrl = 'https://backend.prod.cluster.notify-me.rs';
}

const api = create({
  baseURL: backendUrl,
});

const publicUrl = [
  '/login',
  '/signup',
  '/resend',
  '/reset_password',
  '/register',
  '/oauth/google',
  '/change_feedback',
];

const isPublicUrl = (url: string): boolean => {
  let isPublic = false;
  publicUrl.forEach((u) => {
    if (url.startsWith(u)) {
      isPublic = true;
    }
  });
  return isPublic;
};

api.addRequestTransform((request) => {
  if (!request.url) {
    return;
  }

  if (isPublicUrl(request.url)) {
    return;
  }

  if (request.headers) {
    request.headers.Authorization = 'Bearer ' + getAccessToken();
  }
});

export const login = async (
  password: string,
  email: string,
): Promise<ApiResponse<LoginResponse | string>> =>
  api.post('/login', {
    password,
    email,
  });

export const signup = async (
  password: string,
  email: string,
  packageName: string | null,
) =>
  api.post('/signup', {
    password,
    email,
    package_name: packageName ?? undefined,
  });

export const addSite = async (newSite: TrackedSite) => {
  return api.post('/overview/add_site', {
    name: newSite.siteName,
    url: newSite.siteUrl,
    keywords: newSite.keywords,
  });
};

export const updateSiteInfo = async (
  newUrl: string,
  oldUrl: string,
  name: string,
  keywords: string,
) => {
  return api.patch('/overview/update_site_info', {
    name: name,
    new_url: newUrl,
    old_url: oldUrl,
    keywords: keywords,
  });
};

export const deleteSite = async (siteForDeletion: TrackedSite) => {
  return api.post('/overview/delete_site', {
    url: siteForDeletion.siteUrl,
  });
};

export const getSiteData = async () => {
  return api.get('/overview', {});
};

export const resend = async (email: string) => {
  return api.post('/resend', {
    email,
  });
};

export const resetPassword = async (email: string) => {
  return api.post('/reset_password', {
    email,
  });
};

export const verifyResetPasswordToken = async (token: string) => {
  return api.post(`/reset_password/${token}/verify`, {});
};

export const verifyRegistrationToken = async (token: string) => {
  return api.post(`/register/${token}/verify`, {});
};

export const saveNewPassword = async (newPassword: string, token: string) => {
  return api.post(`/reset_password/${token}`, {
    password: newPassword,
  });
};

export const deleteAccount = async (deletionReason: string) => {
  return api.post('/delete_account', {
    deletion_reason: deletionReason,
  });
};

export const sendReview = async (text: string) => {
  return api.post('/send_review', {
    review_text: text,
  });
};

export const googleOAuth = async (
  accessToken: string,
): Promise<ApiResponse<GoogleOAuthResponse>> => {
  return api.post('/oauth/google', {
    accessToken,
  });
};

export const getBillingInfo = async (): Promise<
  ApiResponse<BillingInfoResponse>
> => {
  return api.get('/billing_info');
};

export const parseSiteHistory = async (
  site_id: string,
  history_id: string,
): Promise<ApiResponse<ParsedSiteHistory>> => {
  return api.post('/parse_site_history', { site_id, history_id });
};

export const changeFeedback = async (
  siteUrl: string,
  oldDiffUrl: string,
  newDiffUrl: string,
  feedbackType: string,
  userEmail: string,
  viewInBrowserLink: string,
) => {
  return api.post('/change_feedback', {
    site_url: siteUrl,
    old_diff_url: oldDiffUrl,
    new_diff_url: newDiffUrl,
    feedback_type: feedbackType,
    user_email: userEmail,
    view_in_browser_link: viewInBrowserLink,
  });
};

export const getPackage = async (packageName: string) => {
  return api.get(`/package/${packageName}`);
};

export const registerOrder = async (orderId: string) => {
  return api.post('/payment/register_order', { order_id: orderId });
};

export const cancelSubscription = async () => {
  return api.post('/payment/cancel_subscription');
};

export const changePlan = async (newPlanId: string) => {
  return api.post('/payment/change_plan', { new_plan_id: newPlanId });
};

export const isDevUser = async () => {
  return api.post('/payment/is_dev_user');
};

export const getTelegramData = async (): Promise<ApiResponse<DiscordData>> => {
  return api.get('/notifications/telegram');
};

export const removeTelegramNotifications = async () => {
  return api.delete('/notifications/telegram');
};

export const getDiscordData = async (): Promise<ApiResponse<DiscordData>> => {
  return api.get('/notifications/discord');
};

export const deleteDiscordData = async () => {
  return api.delete('/notifications/discord');
};

export const updateDiscordData = async (discordWebhook: string) => {
  return api.post('/notifications/discord', {
    discord_webhook: discordWebhook,
  });
};

export const sendAffiliate = async (email: string) => {
  return api.post('/affiliate', { email });
};
