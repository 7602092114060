import React from 'react';
import HelmetComponent from '../../components/HelmetComponent';
import { useNavigate } from 'react-router-dom';

export default function PageNotFound() {
  const navigate = useNavigate();

  return (
    <div className="h-[80%]">
      <HelmetComponent title="404 | Notify Me" description="Page not found." />
      <div className="flex lg:min-h-screen">
        <div className="text-center  m-auto">
          <p className="text-9xl md:text-10xl 3xl:text-11xl font-heading text-main-letters">
            404
          </p>
          <p className="text-3xl lg:text-4xl font-thin mb-4">
            Something is missing!
          </p>
          <p className="text-xl lg:text-2xl font-bold mb-6">
            The page you are looking for might have been removed.
          </p>
          <button
            className="btn btn-primary btn-wide"
            onClick={() => navigate('/')}
          >
            Go back home
          </button>
        </div>
      </div>
    </div>
  );
}
