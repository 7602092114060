import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function ReadyToStartMonitoring() {
  const navigate = useNavigate();

  return (
    <>
      <div className="text-main-background max-w-4xl m-auto">
        <div className="text-center p-8 lg:p-24">
          <p className="text-center text-3xl lg:text-5xl xl:text-6xl font-heading ml-auto">
            Ready to Start Monitoring?
          </p>
          <div className="m-auto max-w-2xl">
            <button
              className="btn bg-main-test1 btn-block btn-lg mt-8"
              onClick={() => navigate('/signup')}
            >
              <span className="text-xl">Get Started Now!</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
