import React, { FC, DetailedHTMLProps, TextareaHTMLAttributes } from 'react';

interface Props
  extends DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  label: string;
  error?: string;
  helperText?: string | JSX.Element;
}

const TextArea: FC<Props> = ({
  id,
  label,
  error,
  helperText,
  ...inputProps
}) => {
  return (
    <div className="mb-6 text-main-background">
      <div>
        <label
          className="text-left block text-base text-gray-100 mb-1.5"
          htmlFor={id}
        >
          {label}
        </label>

        {helperText ? helperText : null}
      </div>

      <textarea
        className="outline-none rounded-xl w-full px-4 py-2 focus:ring text-primary-400 bg-gray-100"
        id={id}
        {...inputProps}
      />

      {error ? (
        <span className="block mt-2 text-sm text-[#660000]">{error}</span>
      ) : null}
    </div>
  );
};

export default TextArea;
