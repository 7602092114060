import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  imageSrc: string;
  title: string;
  path: string;
}

export default function SingleArticlePreview(props: Props) {
  return (
    <div className="cursor-pointer">
      <div className="m-auto p-4">
        <Link to={props.path}>
          <img
            src={props.imageSrc}
            className="border-2 border-white"
            alt="article preview image"
          />
        </Link>
      </div>
      <div className="m-auto p-4">
        <h1 className="text-main-letters text-base md:text-xl font-bold">
          <Link to={props.path}>{props.title}</Link>
        </h1>
      </div>
    </div>
  );
}
