import { createContext, useContext } from 'react';
import { BillingInfo } from '../types';

interface ContextProps {
  getBillingInfo: (_shouldRefresh: boolean) => Promise<BillingInfo | undefined>;
  billingInfo: BillingInfo | undefined;
}

export const BillingInfoContext = createContext<ContextProps | undefined>(
  undefined,
);

export const BillingInfoProvider = BillingInfoContext.Provider;

export const useBillingInfo = () => {
  const context = useContext(BillingInfoContext);

  if (context === undefined) {
    throw new Error(
      'useCurrentUser must be used within a BillingInfoContextProvider',
    );
  }

  return context;
};
