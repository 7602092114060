import React from 'react';
import { IntuitiveNotificationsAnimation } from '../../animations/IntuitiveNotifications';

export default function IntuitiveNotifications() {
  return (
    <div className="mt-4 p-4 m-auto max-w-7xl text-main-background">
      <div>
        <div className="my-8">
          <p className="text-center text-3xl lg:text-5xl xl:text-6xl font-heading p-4 mt-8">
            Intuitive Notifications
          </p>
          <p className="text-xl xl:text-2xl text-center mt-8">
            Whether the change is addition, modification or deletion,
            notifications are color-coded and easy to understand. Additionally,
            you can set up multiple notifications channels such as email,
            Telegram, and Discord.
          </p>
        </div>
        <div className="flex">
          <div className="max-w-6xl m-auto mt-8 mb-16">
            <IntuitiveNotificationsAnimation />
          </div>
        </div>
      </div>
    </div>
  );
}
