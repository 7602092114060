import React, { useState } from 'react';
import * as api from '../../api';
import SubmitSuccessfulDialog from './SubmitSuccesfulDialog';
import LoadingBar from '../../components/LoadingBar';
import { EventType, registerEvent } from '../../plausible';
import HelmetComponent from '../../components/HelmetComponent';
import { resetPasswordValidationSchema } from './validation';
import { useFormik } from 'formik';
import useApiCall from '../../hooks/UseApiCall';
import InputField from '../../components/InputField';

export default function ResetPassword() {
  const [emailValueForResend, setEmailValueForResend] = useState('');
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(false);

  const apiCall = useApiCall(setWaitingForServerResponse);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: resetPasswordValidationSchema,
    onSubmit: async (values) => {
      onSubmit(values.email);
    },
  });

  const onSubmit = async (email: string) => {
    const response = await apiCall(
      api.resetPassword(email),
      'Reset email successfully sent. Please check your inbox.',
    );

    if (response.error) {
      return;
    }

    registerEvent(EventType.RESET_PASSWORD);
    setEmailValueForResend(email);
  };

  const resendResetMail = async () => {
    onSubmit(emailValueForResend);
  };

  return (
    <div className="">
      <HelmetComponent
        title="Log In | Notify Me"
        description="Login page for Notify Me."
        canonicalLink="/reset_password"
      />
      <div className="flex lg:min-h-screen justify-center items-center">
        <form
          className="bg-main-footerLinks bg-opacity-40 p-8 border rounded-xl border-main-letters max-w-sm w-full"
          onSubmit={formik.handleSubmit}
        >
          <div>
            <h1 className="my-4 lg:my-8 font-bold font-heading text-4xl text-main-letters text-center">
              Reset Password
            </h1>
          </div>
          <InputField
            label="email"
            id="email"
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : undefined
            }
          />
          <div></div>
          <button className="btn btn-block btn-primary">Reset Password</button>
          <div>
            <div className="mt-4 text-center">
              <LoadingBar isLoading={waitingForServerResponse} />
            </div>
            <div>
              {emailValueForResend && (
                <SubmitSuccessfulDialog onClick={resendResetMail} />
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
