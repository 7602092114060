import React from 'react';
import Overview from './Overview';
import OverviewMobile from './OverviewMobile';
import { SiteData } from '../types';
interface Props {
  sites: Array<SiteData>;
  onDelete: Function;
  onEdit: Function;
}

export default function SitesOverview(props: Props) {
  return (
    <div className="flex items-center justify-center overflow-hidden ">
      <div className="w-full">
        <Overview
          sites={props.sites}
          onDelete={props.onDelete}
          onEdit={props.onEdit}
        />
        <OverviewMobile
          sites={props.sites}
          onDelete={props.onDelete}
          onEdit={props.onEdit}
        />
      </div>
    </div>
  );
}
