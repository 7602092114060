/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React from 'react';
import HelmetComponent from '../../../components/HelmetComponent';
import { useNavigate } from 'react-router-dom';
import img from './img/automateWebsiteChangeAlertsWithTelegramApi.jpg';
import { automateWebsiteChangeAlertsWithTelegramApiPath } from '../paths';
import { Link } from 'react-router-dom';

export default function AutomateWebsiteChangeAlertsWithTelegramApi() {
  const navigate = useNavigate();
  return (
    <div className="max-w-5xl m-auto p-8">
      <HelmetComponent
        title="Automate Website Change Alerts with Telegram API"
        description={
          'Learn to automate website change alerts with Telegram API for instant updates and improved efficiency in monitoring changes.'
        }
        canonicalLink={`/blog/${automateWebsiteChangeAlertsWithTelegramApiPath}`}
        imageSrc={img}
        type="article"
        keywords="website monitoring, Telegram API, change alerts, automation, web scraping"
      />
      <div className="text-lg">
        <div className="max-w-5xl m-auto">
          <h1 className="nm-default-page-heading">
            Automate Website Change Alerts with Telegram API
          </h1>
          <div className="m-auto mt-8 text-left">
            <p className="mt-4">
              {
                'Learn to automate website change alerts with Telegram API for instant updates and improved efficiency in monitoring changes.'
              }
            </p>
          </div>
          <div>
            <img
              src={img}
              alt="article preview"
              className="mt-8 w-full md:max-w-2xl m-auto"
            />
          </div>

          <div className="text-left m-auto mt-8">
            <p className="mt-4">
              Want instant updates on website changes without constant
              refreshing? Here's how to set up automated alerts using{' '}
              <a href="https://telegram.org/" target="_blank" rel="noreferrer">
                Telegram
              </a>
              :
            </p>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">Create a Telegram bot</li>
              <li className="mt-2">Set up a website monitoring system</li>
              <li className="mt-2">
                Connect the two for seamless notifications
              </li>
            </ol>
            <p className="mt-4">Key benefits:</p>
            <ul className="list-disc list-inside px-2">
              <li className="mt-2">
                Get real-time updates on price changes or news
              </li>
              <li className="mt-2">Save time by eliminating manual checks</li>
              <li className="mt-2">
                React quickly to stay ahead of competitors
              </li>
            </ul>
            <p className="mt-4">What you'll need:</p>
            <ul className="list-disc list-inside px-2">
              <li className="mt-2">Telegram account and app</li>
              <li className="mt-2">
                <a
                  href="https://www.python.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Python
                </a>{' '}
                3 installed
              </li>
              <li className="mt-2">Basic coding knowledge</li>
            </ul>
            <p className="mt-4">Here's a quick overview of the process:</p>
            <table className="w-full table-auto mt-4 border">
              <thead>
                <tr className="bg-gray-200 text-gray-600 text-xs md:text-sm leading-normal">
                  <th className="p-3 md:py-3 md:px-6">Step</th>
                  <th className="p-3 md:py-3 md:px-6">Action</th>
                  <th className="p-3 md:py-3 md:px-6">Purpose</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center p-4">1</td>
                  <td className="text-center p-4">Create Telegram bot</td>
                  <td className="text-center p-4">
                    Sends alerts to your device
                  </td>
                </tr>
                <tr>
                  <td className="text-center p-4">2</td>
                  <td className="text-center p-4">Write monitoring script</td>
                  <td className="text-center p-4">
                    Checks website for changes
                  </td>
                </tr>
                <tr>
                  <td className="text-center p-4">3</td>
                  <td className="text-center p-4">
                    Connect script to Telegram
                  </td>
                  <td className="text-center p-4">
                    Delivers alerts when changes occur
                  </td>
                </tr>
                <tr>
                  <td className="text-center p-4">4</td>
                  <td className="text-center p-4">Automate the process</td>
                  <td className="text-center p-4">
                    Runs checks without manual input
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="mt-4">
              By the end, you'll have a system that checks your chosen websites
              every few minutes and sends you Telegram alerts about any changes.
            </p>
            <h2 className="text-main-letters text-3xl font-heading mt-4">
              Related video from YouTube
            </h2>
            <iframe
              className="w-full h-96 mt-4"
              src="https://www.youtube-nocookie.com/embed/ps1yeWwd6iA"
              loading="lazy"
            ></iframe>
            <h2 className="text-main-letters text-3xl font-heading mt-4">
              What You Need to Start
            </h2>
            <p className="mt-4">
              Let's get you set up to automate website change alerts with
              Telegram API. Here's what you'll need:
            </p>
            <h3 className="text-main-letters text-2xl font-heading mt-4">
              <a href="https://telegram.org/" target="_blank" rel="noreferrer">
                Telegram
              </a>{' '}
              Setup
            </h3>
            <p className="mt-4">
              <img
                src="https://mars-images.imgix.net/seobot/screenshots/telegram.org-288556320828451136f2fa7ac44f5340.jpg?auto=compress"
                alt="Telegram"
              />
            </p>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">Get Telegram on your device</li>
              <li className="mt-2">Make an account (it's free)</li>
              <li className="mt-2">Find BotFather in Telegram</li>
              <li className="mt-2">
                Chat with BotFather, use{' '}
                <code className="p-2 bg-gray-200 text-black">/newbot</code>
              </li>
              <li className="mt-2">Name your bot, get your API token</li>
            </ol>
            <h3 className="text-main-letters text-2xl font-heading mt-4">
              API Basics
            </h3>
            <p className="mt-4">
              APIs let different software talk. You'll use:
            </p>
            <ul className="list-disc list-inside px-2">
              <li className="mt-2">Telegram Bot API for alerts</li>
              <li className="mt-2">
                Web scraping or site APIs to check changes
              </li>
            </ul>
            <h3 className="text-main-letters text-2xl font-heading mt-4">
              Coding Prep
            </h3>
            <p className="mt-4">For your monitoring script:</p>
            <ul className="list-disc list-inside px-2">
              <li className="mt-2">Install Python 3</li>
              <li className="mt-2">
                Pick a code editor (
                <a
                  href="https://code.visualstudio.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  VS Code
                </a>
                , Notepad++)
              </li>
            </ul>
            <p className="mt-4">Get these Python libraries:</p>
            <p className="mt-4">
              <pre className="mt-2 bg-gray-200 text-black overflow-auto">
                <code className="p-2 bg-gray-200 text-black">
                  pip install requests beautifulsoup4 python-telegram-bot
                </code>
              </pre>
            </p>
            <p className="mt-4">Now you're ready to build your alert system!</p>
            <h2 className="text-main-letters text-3xl font-heading mt-4">
              Creating Your Telegram Bot
            </h2>
            <p className="mt-4">
              Let's set up your Telegram bot. It's quick and easy.
            </p>
            <h3 className="text-main-letters text-2xl font-heading mt-4">
              Using{' '}
              <a
                href="https://telegram.me/BotFather"
                target="_blank"
                rel="noreferrer"
              >
                BotFather
              </a>
            </h3>
            <p className="mt-4">
              <img
                src="https://mars-images.imgix.net/seobot/screenshots/telegram.me-5de23c79a27436dbe9eb7e565640e5fe.jpg?auto=compress"
                alt="BotFather"
              />
            </p>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">Find @BotFather on Telegram</li>
              <li className="mt-2">Start a chat</li>
              <li className="mt-2">
                Type <code className="p-2 bg-gray-200 text-black">/newbot</code>
              </li>
              <li className="mt-2">Name your bot</li>
              <li className="mt-2">Choose a username ending with bot</li>
            </ol>
            <p className="mt-4">
              BotFather will guide you. If a username's taken, try another.
            </p>
            <h3 className="text-main-letters text-2xl font-heading mt-4">
              Getting API Token and Chat ID
            </h3>
            <p className="mt-4">
              BotFather gives you an API token. Keep it secret.
            </p>
            <p className="mt-4">For your Chat ID:</p>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">Message your new bot</li>
              <li className="mt-2">
                Visit:{' '}
                <code className="p-2  bg-gray-200 text-black">
                  https://api.telegram.org/bot&lt;YOUR_API_TOKEN&gt;/getUpdates
                </code>
              </li>
              <li className="mt-2">Find chat in the JSON response</li>
            </ol>
            <p className="mt-4">Here's what you need:</p>
            <table className="w-full table-auto mt-4 border">
              <thead>
                <tr className="bg-gray-200 text-gray-600 text-xs md:text-sm leading-normal">
                  <th className="p-3 md:py-3 md:px-6">Item</th>
                  <th className="p-3 md:py-3 md:px-6">Source</th>
                  <th className="p-3 md:py-3 md:px-6">Purpose</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center p-4">API Token</td>
                  <td className="text-center p-4">BotFather</td>
                  <td className="text-center p-4">
                    Lets your script use the bot
                  </td>
                </tr>
                <tr>
                  <td className="text-center p-4">Chat ID</td>
                  <td className="text-center p-4">getUpdates JSON</td>
                  <td className="text-center p-4">
                    Tells bot where to send messages
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="mt-4">
              Keep your API token private. If exposed, get a new one with{' '}
              <code className="p-2 bg-gray-200 text-black">/token</code>{' '}
              command.
            </p>
            <p className="mt-4">
              Now you're ready to set up website monitoring.
            </p>
            <h2 className="text-main-letters text-3xl font-heading mt-4">
              Picking a Website Monitoring Method
            </h2>
            <p className="mt-4">
              When it comes to automated website change alerts, you've got two
              main choices: web scraping and APIs. Let's break them down:
            </p>
            <h3 className="text-main-letters text-2xl font-heading mt-4">
              Web Scraping: The Flexible Option
            </h3>
            <p className="mt-4">
              Web scraping is like having a robot visit a website and take
              notes. Here's how it works:
            </p>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">A script checks the website regularly</li>
              <li className="mt-2">It grabs the data you care about</li>
              <li className="mt-2">The script compares new and old data</li>
              <li className="mt-2">
                If something's different, you get an alert
              </li>
            </ol>
            <p className="mt-4">
              <strong>The Good and the Bad:</strong>
            </p>
            <blockquote>
              <p className="mt-4">
                Web scraping is like fishing with a net. You might catch what
                you want, but you'll probably grab some extra stuff too. -
                Anonymous Developer
              </p>
            </blockquote>
            <table className="w-full table-auto mt-4 border">
              <thead>
                <tr className="bg-gray-200 text-gray-600 text-xs md:text-sm leading-normal">
                  <th className="p-3 md:py-3 md:px-6">Pros</th>
                  <th className="p-3 md:py-3 md:px-6">Cons</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center p-4">Works on any public site</td>
                  <td className="text-center p-4">Can break if site changes</td>
                </tr>
                <tr>
                  <td className="text-center p-4">
                    Grab whatever data you want
                  </td>
                  <td className="text-center p-4">Might get blocked</td>
                </tr>
                <tr>
                  <td className="text-center p-4">No API needed</td>
                  <td className="text-center p-4">Slower than APIs</td>
                </tr>
              </tbody>
            </table>
            <h3 className="text-main-letters text-2xl font-heading mt-4">
              APIs: The Direct Line
            </h3>
            <p className="mt-4">
              APIs are like a drive-thru for data. If a website offers one, it's
              often the smoother choice.
            </p>
            <p className="mt-4">How it goes:</p>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">Your script asks the API for data</li>
              <li className="mt-2">The API hands over neat, organized info</li>
              <li className="mt-2">Your script checks for changes</li>
              <li className="mt-2">If something's new, you get a heads-up</li>
            </ol>
            <p className="mt-4">
              <strong>The Ups and Downs:</strong>
            </p>
            <table className="w-full table-auto mt-4 border">
              <thead>
                <tr className="bg-gray-200 text-gray-600 text-xs md:text-sm leading-normal">
                  <th className="p-3 md:py-3 md:px-6">Pros</th>
                  <th className="p-3 md:py-3 md:px-6">Cons</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center p-4">Fast and reliable</td>
                  <td className="text-center p-4">Not all sites have them</td>
                </tr>
                <tr>
                  <td className="text-center p-4">Data comes pre-organized</td>
                  <td className="text-center p-4">
                    Might cost money or have limits
                  </td>
                </tr>
                <tr>
                  <td className="text-center p-4">
                    Less likely to get blocked
                  </td>
                  <td className="text-center p-4">
                    You'll need to learn the API
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="mt-4">
              <strong>So, which one?</strong>
            </p>
            <p className="mt-4">Ask yourself:</p>
            <ul className="list-disc list-inside px-2">
              <li className="mt-2">Does the site have an API?</li>
              <li className="mt-2">How often do you need updates?</li>
              <li className="mt-2">What exactly are you watching for?</li>
            </ul>
            <p className="mt-4">
              For our Telegram bot, web scraping might be your best bet if
              you're eyeing lots of different sites. But if you're zeroing in on
              places with solid APIs, that could be your fast track.
            </p>
            <h2 className="text-main-letters text-3xl font-heading mt-4">
              Writing the Monitoring Script
            </h2>
            <p className="mt-4">
              Let's build a Python script to detect website changes. Here's how:
            </p>
            <h3 className="text-main-letters text-2xl font-heading mt-4">
              Creating the Main Code
            </h3>
            <p className="mt-4">1. Set up the environment:</p>
            <p className="mt-4">
              <pre className="mt-2 bg-gray-200 text-black overflow-auto">
                <code className="p-2 bg-gray-200 text-black">
                  pip install requests beautifulsoup4 lxml
                </code>
              </pre>
            </p>
            <p className="mt-4">2. Import libraries and set up variables:</p>
            <pre className="bg-gray-200 text-black p-2 overflow-auto">
              <code className="p-2">
                {`
import requests
from bs4 import BeautifulSoup
import time
from datetime import datetime

url = "https://example.com"
headers = {'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36'}
prev_version = ""
first_run = True`}
              </code>
            </pre>
            <p className="mt-4">3. Create the main loop:</p>
            <pre className="bg-gray-200 text-black overflow-auto mt-4">
              <code className="p-2 ">
                {`while True:
    response = requests.get(url, headers=headers)
    soup = BeautifulSoup(response.text, "lxml")

    for script in soup(["script", "style"]):
        script.extract()

    current_version = soup.get_text()

    if prev_version != current_version:
        if first_run:
            prev_version = current_version
            first_run = False
            print(f"Started monitoring {url} at {datetime.now()}")
        else:
            print(f"Changes detected at: {datetime.now()}")
            # Add Telegram notification here
    else:
        print(f"No changes at {datetime.now()}")

    time.sleep(300)  # Check every 5 minutes`}
              </code>
            </pre>
            <p className="mt-4">
              This script checks the website every 5 minutes and alerts you to
              changes.
            </p>
            <h3 className="text-main-letters text-2xl font-heading mt-4">
              Detecting Different Changes
            </h3>
            <p className="mt-4">Want to spot specific updates? Here's how:</p>
            <p className="mt-4">
              1. Text changes: We're already doing this in the main code.
            </p>
            <p className="mt-4">2. HTML structure changes:</p>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 ">current_version = str(soup)</code>
            </pre>
            <p className="mt-4">3. Specific element changes:</p>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2">
                element = soup.find('div', class_='target-className')
                current_version = str(element)
              </code>
            </pre>
            <p className="mt-4">4. Image changes:</p>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2">
                images = soup.find_all('img') current_version =
                ''.join([img['src'] for img in images])
              </code>
            </pre>
            <p className="mt-4">
              Adjust the{' '}
              <pre className="mt-2 bg-gray-200 text-black overflow-auto">
                <code className="p-2">current_version</code>
              </pre>{' '}
              variable to focus on the changes you care about.
            </p>
            <p className="mt-4">
              Let's connect our monitoring script to Telegram for alerts. Here's
              how:
            </p>
            <h3 className="text-main-letters text-2xl font-heading mt-4">
              Setting Up Alert Messages
            </h3>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">Install the library:</li>
            </ol>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 ">
                pip install python-telegram-bot --pre --upgrade
              </code>
            </pre>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">Set up your bot:</li>
            </ol>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 ">
                import asyncio from telegram import Bot TELEGRAM_BOT_TOKEN =
                'your_bot_token_here' CHAT_ID = 'your_chat_id_here' bot =
                Bot(token=TELEGRAM_BOT_TOKEN) async def send_message(text):
                async with bot: await bot.send_message(text=text,
                chat_id=CHAT_ID)
              </code>
            </pre>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">Modify your main loop:</li>
            </ol>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 ">
                {' '}
                {`while True:
    # (previous code)

    if prev_version != current_version:
        if not first_run:
            alert_message = f"Changes detected at: {datetime.now()}"
            asyncio.run(send_message(alert_message))

    # (rest of the loop)`}
              </code>
            </pre>
            <h3 className="text-main-letters text-2xl font-heading mt-4">
              Writing Clear Alert Messages
            </h3>
            <p className="mt-4">Make your alerts useful:</p>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">Be specific:</li>
            </ol>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 ">
                {' '}
                {`alert_message = f"Change on {url} at {datetime.now()}:\\n"
alert_message += f"Old: {prev_version[:100]}...\\n"
alert_message += f"New: {current_version[:100]}..."`}
              </code>
            </pre>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">Add actionable info:</li>
            </ol>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 ">
                {'alert_message += f\nSee changes: {url}'}
              </code>
            </pre>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">Format for readability:</li>
            </ol>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 bg-gray-200 text-black">
                {`alert_message = f"🚨 *ALERT: Website Changed* 🚨\\n\\n"
alert_message += f"*URL:* {url}\\n"
alert_message += f"*Time:* {datetime.now().strftime('%Y-%m-%d %H:%M:%S')}\\n"
alert_message += f"*What:* Content updated.\\n\\n"
alert_message += f"View here: {url}"

async def send_formatted_message(text):
    async with bot:
        await bot.send_message(text=text, chat_id=CHAT_ID, parse_mode='Markdown')`}
              </code>
            </pre>
            <p className="mt-4">
              These changes will keep you informed about website updates via
              Telegram.
            </p>
            <div className="bg-main-test1 p-8 rounded-xl mt-4 text-main-background ">
              <p className="text-3xl font-heading">
                Get Telegram alerts with Notify Me
              </p>
              <p className="mt-4 text-base">
                {
                  "Keep track of any website changes easily and receive instant alerts. Simplify your updates with Notify Me's effective monitoring tool. You can receive updates on telegram, discord and email"
                }
              </p>
              <button
                className="btn btn-wide bg-main-background text-white mt-4"
                onClick={() => navigate('/')}
              >
                Start Monitoring Now
              </button>
            </div>
            <h2 className="text-main-letters text-3xl font-heading mt-4">
              Automating the Process
            </h2>
            <p className="mt-4">
              Let's make our monitoring script run on its own. No more manual
              checks needed!
            </p>
            <h3 className="text-main-letters text-2xl font-heading mt-4">
              Set Up Regular Checks
            </h3>
            <p className="mt-4">We'll use cron jobs to automate. Here's how:</p>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">Open your terminal and type:</li>
            </ol>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 ">crontab -e</code>
            </pre>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">Add this line to run every 15 minutes:</li>
            </ol>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 ">
                */15 * * * * /usr/bin/python3 /path/to/your/script.py
              </code>
            </pre>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">Save and exit.</li>
            </ol>
            <p className="mt-4">Windows users: Use Task Scheduler instead.</p>
            <h3 className="text-main-letters text-2xl font-heading mt-4">
              Handle Potential Errors
            </h3>
            <p className="mt-4">
              Even great scripts can hiccup. Here's how to deal with common
              issues:
            </p>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">
                <strong>HTTP Errors</strong>: Catch and handle them like this:
              </li>
            </ol>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 ">
                {' '}
                {`import requests

try:
    response = requests.get(url)
    response.raise_for_status()
except requests.exceptions.HTTPError as err:
    print(f"HTTP error occurred: {err}")
    # Alert via Telegram`}
              </code>
            </pre>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">
                <strong>Connection Errors</strong>: Use a retry mechanism:
              </li>
            </ol>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 ">
                {`from requests.adapters import HTTPAdapter
from requests.packages.urllib3.util.retry import Retry

session = requests.Session()
retry = Retry(total=5, backoff_factor=0.1)
adapter = HTTPAdapter(max_retries=retry)
session.mount('http://', adapter)
session.mount('https://', adapter)

try:
    response = session.get(url)
except requests.exceptions.ConnectionError as err:
    print(f"Connection error occurred: {err}")
    # Alert via Telegram`}
              </code>
            </pre>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">
                <strong>Parsing Errors</strong>: Check if data is as expected:
              </li>
            </ol>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 ">
                {`from bs4 import BeautifulSoup

try:
    soup = BeautifulSoup(response.content, 'html.parser')
    target_element = soup.find('div', class_='target-className')
    if target_element:
        # Process the element
    else:
        print("Target element not found. Website structure might have changed.")
        # Alert via Telegram
except Exception as err:
    print(f"Parsing error occurred: {err}")
    # Alert via Telegram`}
              </code>
            </pre>
            <h2 className="text-main-letters text-3xl font-heading mt-4">
              Checking and Improving Your Setup
            </h2>
            <h3 className="text-main-letters text-2xl font-heading mt-4">
              Testing the Whole System
            </h3>
            <p className="mt-4">
              Let's make sure your website change alert system actually works:
            </p>
            <p className="mt-4">
              1. Send a test notification through your Telegram bot:
            </p>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 ">
                {`import requests

bot_token = 'YOUR_BOT_TOKEN'
chat_id = 'YOUR_CHAT_ID'
test_message = 'Test alert from your website monitor.'

url = f'https://api.telegram.org/bot{bot_token}/sendMessage'
params = {'chat_id': chat_id, 'text': test_message}

response = requests.get(url, params=params)
print(f'Test message sent. Response: {response.status_code}')`}
              </code>
            </pre>
            <p className="mt-4">
              2. Check your Telegram chat. Did you get the message?
            </p>
            <p className="mt-4">
              3. Make a tiny change to your monitored website. Run your script.
              Did it catch the change and send an alert?
            </p>
            <p className="mt-4">
              4. No alert? Double-check your script and Telegram bot settings.
            </p>
            <h3 className="text-main-letters text-2xl font-heading mt-4">
              Adjusting Your Alerts
            </h3>
            <p className="mt-4">Make your alerts work better for you:</p>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">
                <strong>Frequency</strong>: How often should you check for
                changes? Too often might hit API limits. Too seldom might miss
                updates.
              </li>
              <li className="mt-2">
                <strong>Content</strong>: Make your alerts clear. Include:
                <ul className="list-disc list-inside px-2">
                  <li className="mt-2">What changed</li>
                  <li className="mt-2">Where it changed (URL)</li>
                  <li className="mt-2">When it changed</li>
                </ul>
              </li>
              <li className="mt-2">
                <strong>Error handling</strong>: Add this to your script:
              </li>
            </ol>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 ">
                {`try:
    response = requests.get(url)
    response.raise_for_status()
except requests.exceptions.RequestException as err:
    error_message = f"Error: {err}"
    send_telegram_alert(error_message)`}
              </code>
            </pre>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">
                <strong>API limits</strong>: Telegram allows 30 messages per
                second for bots. If you hit this:
              </li>
            </ol>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 ">
                import time if response.status_code == 429: retry_after =
                response.json()['parameters']['retry_after']
                time.sleep(retry_after) # Resend the message
              </code>
            </pre>
            <h2 className="text-main-letters text-3xl font-heading mt-4">
              Extra Features to Add
            </h2>
            <p className="mt-4">
              Let's supercharge your website change alert system with some cool
              extras.
            </p>
            <h3 className="text-main-letters text-2xl font-heading mt-4">
              Personalizing Your Alerts
            </h3>
            <p className="mt-4">Make your alerts work for you:</p>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">
                <strong>Keyword tracking</strong>: Catch specific words or
                phrases.
              </li>
            </ol>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 ">
                {`if keyword in website_content:
    send_telegram_alert(f"Found '{keyword}' on {url}")`}
              </code>
            </pre>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">
                <strong>Section monitoring</strong>: Zero in on specific webpage
                parts.
              </li>
            </ol>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 ">
                soup = BeautifulSoup(website_content, 'html.parser')
                target_section = soup.find('div', class_='target-className') if
                target_section and 'important update' in target_section.text:
                send_telegram_alert(Update in target section)
              </code>
            </pre>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">
                <strong>Change threshold</strong>: Only alert on big changes.
              </li>
            </ol>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 ">
                if SequenceMatcher(None, old_content, new_content).ratio() &lt;
                0.9: send_telegram_alert(Major change detected)
              </code>
            </pre>
            <h3 className="text-main-letters text-2xl font-heading mt-4">
              Adding User Commands
            </h3>
            <p className="mt-4">Give users control with these commands:</p>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">
                <strong>Check now</strong>: Trigger a manual check.
              </li>
            </ol>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 ">
                {`@bot.message_handler(commands=['check'])
def check_now(message):
    result = check_website()
    bot.reply_to(message, f"Check result: {result}")`}
              </code>
            </pre>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">
                <strong>Add website</strong>: Monitor new sites.
              </li>
            </ol>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 ">
                {`@bot.message_handler(commands=['add'])
def add_website(message):
    url = message.text.split(' ', 1)[1]
    websites.append(url)
    bot.reply_to(message, f"Added {url}")`}
              </code>
            </pre>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">
                <strong>List websites</strong>: Show monitored sites.
              </li>
            </ol>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 ">
                {`@bot.message_handler(commands=['list'])
def list_websites(message):
    sites = "\\n".join(websites)
    bot.reply_to(message, f"Monitored sites:\\n{sites}")`}
              </code>
            </pre>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">
                <strong>Remove website</strong>: Stop monitoring a site.
              </li>
            </ol>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 ">
                {`@bot.message_handler(commands=['remove'])
def remove_website(message):
    url = message.text.split(' ', 1)[1]
    if url in websites:
        websites.remove(url)
        bot.reply_to(message, f"Removed {url}")
    else:
        bot.reply_to(message, "Site not found")`}
              </code>
            </pre>
            <h2 className="text-main-letters text-3xl font-heading mt-4">
              Fixing Common Problems
            </h2>
            <p className="mt-4">
              Setting up a website change alert system with Telegram API can be
              tricky. Here's how to tackle common issues:
            </p>
            <h3 className="text-main-letters text-2xl font-heading mt-4">
              Script Errors
            </h3>
            <p className="mt-4">Script errors pop up for a few reasons:</p>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">
                <strong>CORS Issues</strong>: When your script tries to access
                stuff from another domain.
              </li>
            </ol>
            <p className="mt-4">Fix: Add this to your script tag:</p>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="language-html">
                &lt;script crossorigin=anonymous
                src=//another-domain.com/index.js&gt;&lt;/script&gt;
              </code>
            </pre>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">
                <strong>Network Problems</strong>: Slow connections or timeouts.
              </li>
            </ol>
            <p className="mt-4">
              Fix: Bump up script execution intervals or blacklist slow
              resources.
            </p>
            <h3 className="text-main-letters text-2xl font-heading mt-4">
              Telegram Bot API Errors
            </h3>
            <ul className="list-disc list-inside px-2">
              <li className="mt-2">
                <strong>Error 400</strong>: Usually means the group you're
                messaging is dead.
              </li>
            </ul>
            <p className="mt-4">
              Fix: Check if the group's alive and your bot's still in it.
            </p>
            <ul className="list-disc list-inside px-2">
              <li className="mt-2">
                <strong>Error 403</strong>: Your bot got kicked out.
              </li>
            </ul>
            <p className="mt-4">Fix: Add the bot back to the group.</p>
            <h3 className="text-main-letters text-2xl font-heading mt-4">
              Automation Code Issues
            </h3>
            <p className="mt-4">
              YAML indentation errors are a pain. Here's how it should look:
            </p>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="language-yaml">
                {`automation:
  alias: 'Telegram Temperature Alert'
  trigger:
    platform: telegram_text
    text: 'temperature salon'
  action:
    service: telegram_bot.send_message
    data:
      message: "Current temperature: {{ states('sensor.temperature') }}"`}
              </code>
            </pre>
            <h3 className="text-main-letters text-2xl font-heading mt-4">
              Need More Help?
            </h3>
            <ol className="list-disc list-inside px-2">
              <li className="mt-2">
                Check Telegram Bot API docs for error codes.
              </li>
              <li className="mt-2">
                Hit up{' '}
                <a
                  href="https://stackoverflow.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Stack Overflow
                </a>{' '}
                for coding problems.
              </li>
              <li className="mt-2">
                Look through{' '}
                <a href="https://github.com/" target="_blank" rel="noreferrer">
                  GitHub
                </a>{' '}
                issues for open-source tools.
              </li>
              <li className="mt-2">
                Contact Telegram support if you're really stuck.
              </li>
            </ol>
            <h2 className="text-main-letters text-3xl font-heading mt-4">
              Wrap-up
            </h2>
            <p className="mt-4">
              Setting up website change alerts with Telegram API is a smart way
              to track updates without constant checking. Here's what you need
              to know:
            </p>
            <ul className="list-disc list-inside px-2">
              <li className="mt-2">
                No coding skills needed. Just create a Telegram bot with
                BotFather and get your API token.
              </li>
              <li className="mt-2">
                Monitor price changes, news, or any website data you care about.
              </li>
              <li className="mt-2">
                Some services check your site every 30 seconds from multiple
                locations.
              </li>
              <li className="mt-2">Customize alerts to fit your needs.</li>
              <li className="mt-2">
                For developers, the Telegram Bot API is flexible. Here's a
                Python example:
              </li>
            </ul>
            <pre className="mt-2 bg-gray-200 text-black overflow-auto">
              <code className="p-2 ">
                {`import requests
url = "https://api.telegram.org/bot<API_TOKEN>/sendMessage"
payload = 'chat_id=<YOUR_USER_ID>&text=Hello'
headers = {'Content-Type': 'application/x-www-form-urlencoded'}
response = requests.request("POST", url, headers=headers, data=payload)
print(response.text)`}
              </code>
            </pre>
            <ul className="list-disc list-inside px-2">
              <li className="mt-2">
                Protect your API token when integrating Telegram bots.
              </li>
              <li className="mt-2">
                Watch out for Telegram's messaging limits: 20 messages per
                minute to groups, 1 per second to individuals.
              </li>
            </ul>
            <p className="mt-4">
              With these tips, you'll be on top of website changes in no time.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
