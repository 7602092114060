import React, { FC, DetailedHTMLProps, InputHTMLAttributes } from 'react';

interface Props
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
  error?: string;
  helperText?: string | JSX.Element;
}

const InputField: FC<Props> = ({
  id,
  label,
  error,
  helperText,
  ...inputProps
}) => {
  return (
    <div className="mb-4 text-main-background">
      <div className="">
        <label
          className="text-left block text-sm text-gray-100 mb-1"
          htmlFor={id}
        >
          {label}
        </label>

        {helperText ? helperText : null}
      </div>

      <input
        className="outline-none rounded-xl w-full px-4 py-2 focus:ring text-primary-400 bg-gray-100"
        id={id}
        {...inputProps}
      />

      {error ? (
        <span className="block text-xs text-error p-1">{error}</span>
      ) : null}
    </div>
  );
};

export default InputField;
