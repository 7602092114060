import React from 'react';
import HelmetComponent from '../../../components/HelmetComponent';
import { useNavigate } from 'react-router-dom';
import img from './img/affiliateLinks/affiliateLinksTracking.png';
import addSiteImg from './img/affiliateLinks/add_site.png';
import changeImg from './img/affiliateLinks/change.png';
import notificationsImg from './img/affiliateLinks/notifications.png';
import { affiliateLinksPath } from '../paths';

export default function AffiliateLinks() {
  const navigate = useNavigate();

  return (
    <div className="max-w-7xl m-auto p-8">
      <HelmetComponent
        title="Boost Affiliate Revenue with Smart Link Checks"
        description="Maximize your affiliate marketing revenue with Notify Me's effective link checking strategies and boost your online success."
        canonicalLink={`/blog/${affiliateLinksPath}`}
        imageSrc={img}
        type="article"
      />
      <div className="text">
        <div className="max-w-2xl m-auto">
          <h1 className="nm-default-page-heading">
            Boost Your Affiliate Marketing Revenue with Effective Link Checking
            Strategies
          </h1>
          <div className="m-auto mt-8 text-left">
            <p>
              {
                'If you run affiliate programs, it’s important that you know that all of your links are working properly, and that there are no unwanted changes. As the number of links you are managing grows this can become a huge issue. Having up to date links can greatly help your business to maintain trust and increase revenue.'
              }
            </p>
          </div>
          <div>
            <img
              src={img}
              alt="Affiliate Links Tracking"
              className="mt-8 w-full md:max-w-2xl m-auto"
            />
          </div>

          <div className="text-left m-auto mt-8">
            <h2 className="text-main-letters text-2xl font-heading">
              The Challenge of Monitoring Numerous Affiliate Links
            </h2>
            <p className="mt-4">
              The sheer scale of having thousands of affiliate links makes it
              impossible to manually check every one of them. Changes can happen
              overnight, and you have no way of knowing that your links are
              down. Some of the most common problems are:
            </p>
            <ul className="list-decimal list-inside">
              <li className="mt-4">
                <b>Rerouting of traffic</b> - Someone rerouted your link to a
                different page.
              </li>
              <li className="mt-4">
                <b>Bad Links</b> - Links can sometimes stop working. You need to
                ensure that you have as little broken links as possible.
              </li>
              <li className="mt-4">
                <b>Changes on page you are linking to</b> - Page can change,
                along the price and even the product that is advertised there.
              </li>
            </ul>
          </div>
          <div className="text-left m-auto mt-8">
            <h2 className="text-main-letters text-2xl font-heading">
              Why you should have affiliate links?
            </h2>
            <p className="mt-4">
              Affiliate links are one of the easiest way you can drive traffic
              to your site. Advertisers do most of the work for you for a small
              commission. This, while relatively simple to set up, makes it
              extremely hard to ensure that advertisers adhere to the
              agreed-upon terms and not manipulate traffic redirection.
            </p>
            <div className="mt-4">
              <h2 className="text-main-letters text-2xl font-heading">
                Using Notify Me in Your Affiliate Marketing Strategy
              </h2>
              <p className="mt-4">
                Notify Me can easily track all of your affiliate links. We can
                alert you whenever a content on the link changes or when someone
                changes any kind of content on the page you are linking to.
              </p>
              <p className="mt-4">
                By using Notify Me you get automated alerts which you can
                receive on multiple notification channels.
              </p>
            </div>
          </div>
          <div className="text-left m-auto mt-8">
            <h2 className="text-main-letters text-2xl font-heading">
              Setting Up Affiliate Links Checking With Notify Me
            </h2>
            <p className="mt-4">1. Add all relevant sites you want to track</p>
            <div className="border mt-4">
              <img
                src={addSiteImg}
                alt="Add Site Page"
                className="mt-8 w-full md:max-w-2xl m-auto"
              />
            </div>

            <p className="mt-4">2. Set up notifications</p>
            <div className="border mt-4">
              <img
                src={notificationsImg}
                alt="Notifications Page"
                className="mt-8 w-full md:max-w-2xl m-auto"
              />
            </div>
            <p className="mt-4">
              3. Receive automated alerts whenever any tracked site changes.
            </p>
            <div className="border mt-4">
              <img
                src={changeImg}
                alt="Alert Image"
                className="mt-8 w-full md:max-w-2xl m-auto"
              />
            </div>
          </div>
          <div className="text-left m-auto mt-8">
            <h2 className="text-main-letters text-2xl font-heading">
              Why you should have affiliate links?
            </h2>
            <p className="mt-4">
              Affiliate links are one of the easiest way you can drive traffic
              to your site. Advertisers do most of the work for you for a small
              commission. This, while relatively simple to set up, makes it
              extremely hard to ensure that advertisers adhere to the
              agreed-upon terms and not manipulate traffic redirection.
            </p>
            <div className="mt-4">
              <h2 className="text-main-letters text-2xl font-heading">
                Conclusion
              </h2>
              <p className="mt-4">
                Affiliate link checking is an extremely important thing to have,
                especially when working with large number of links. Having
                proper links can increase your revenue, and ensure continued
                trust of your customers.
              </p>
              <p className="mt-4">
                Tracking affiliate links is not easy, and must be ideally done
                proactively, before there are any problems. Manually checking
                thousands of links is not the option.
              </p>
              <p className="mt-4">
                Notify Me can enable you to ensure that all of your links are
                working as expected and it can send you alerts whenever
                something changes so you can react quickly.
              </p>
            </div>
          </div>

          <div className="mt-4 max-w-2xl m-auto">
            <button
              className="btn btn-primary btn-block"
              onClick={() => navigate('/signup')}
            >
              Sign Up - It&apos;s Free
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
