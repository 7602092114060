import React from 'react';
interface Props {
  header: string;
  text: string;
}

export default function CardWithText(props: Props) {
  return (
    <div className="card border-2 border-main-background m-4 text-main-background">
      <div className="card-body">
        <h2 className="card-title font-heading">{props.header}</h2>
        <p className="text-sm">{props.text}</p>
      </div>
    </div>
  );
}
