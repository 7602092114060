import React, { useEffect, useState } from 'react';
import { CheckmarkIcon } from 'react-hot-toast';
import useInterval from '../hooks/UseInterval';
export const contentTimeSaver = () => {
  return (
    <>
      <p>
        Stop wasting time constantly checking for updates on the website
        you&apos;re interested in.
      </p>
      <p className="mt-8">
        Sign up for Notify Me to keep up with the latest site updates, and spend
        your valuable time on what really matters.
      </p>
    </>
  );
};

export const TrackSitesQuicklyAndEasilyAnimation = () => {
  const siteNameContent = 'my cool site';
  const siteUrlContent = 'my-cool-site.com';

  const SITE_NAME_FRAMES = siteNameContent.length;
  const SITE_URL_FRAMES = siteUrlContent.length;
  const ADD_SITE_BUTTON_FRAMES = 5;
  const POPUP_FRAMES = 5;

  const COMPLETE_DURATION =
    SITE_NAME_FRAMES + SITE_URL_FRAMES + ADD_SITE_BUTTON_FRAMES + POPUP_FRAMES;

  const DEFAULT_FRAMERATE = 200;

  const [siteName, setSiteName] = useState('');
  const [siteUrl, setSiteUrl] = useState('');
  const [addSiteAnimationEnabled, setAddSiteAnimationEnabled] = useState(false);
  const [popupAnimationEnabled, setPopupAnimationEnabled] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(0);

  useInterval(() => {
    setCurrentFrame((currentFrame) => {
      if (currentFrame < COMPLETE_DURATION) {
        return currentFrame + 1;
      }
      return 0;
    });
  }, DEFAULT_FRAMERATE);

  useEffect(() => {
    setSiteUrl(() => {
      if (currentFrame < SITE_URL_FRAMES) {
        return siteUrlContent.substring(0, currentFrame);
      }
      return siteUrlContent;
    });
    setSiteName(() => {
      if (currentFrame < SITE_URL_FRAMES) {
        return '';
      }
      if (
        currentFrame >= SITE_URL_FRAMES &&
        currentFrame < SITE_URL_FRAMES + SITE_NAME_FRAMES
      ) {
        return siteNameContent.substring(0, currentFrame - SITE_URL_FRAMES);
      }
      return siteNameContent;
    });
    setAddSiteAnimationEnabled(() => {
      if (
        currentFrame >= SITE_URL_FRAMES + SITE_NAME_FRAMES &&
        currentFrame <
          SITE_URL_FRAMES + SITE_NAME_FRAMES + ADD_SITE_BUTTON_FRAMES
      ) {
        return true;
      }
      return false;
    });
    setPopupAnimationEnabled(() => {
      if (
        currentFrame >=
          SITE_URL_FRAMES + SITE_NAME_FRAMES + ADD_SITE_BUTTON_FRAMES &&
        currentFrame <
          SITE_URL_FRAMES +
            SITE_NAME_FRAMES +
            ADD_SITE_BUTTON_FRAMES +
            POPUP_FRAMES
      ) {
        return true;
      }
      return false;
    });
  }, [currentFrame, SITE_NAME_FRAMES, SITE_URL_FRAMES]);

  const determineClassAddSite = () => {
    if (addSiteAnimationEnabled) {
      return 'w-full bg-main-letters rounded-xl font-bold p-2.5 nm-time-saver-button';
    }
    return 'w-full bg-main-letters rounded-xl font-bold p-2.5';
  };

  const determineClassPopup = () => {
    if (popupAnimationEnabled) {
      return 'nm-time-saver-popup text-center w-full flex p-4 rounded-3xl bg-gray-100 text-green-600 nm-time-saver-popup';
    }
    return 'text-center w-full flex p-4 rounded-3xl bg-gray-100 text-green-600 invisible';
  };

  return (
    <div className="bg-main-background p-8 border rounded-xl border-main-letters max-w-sm w-full">
      <div className={determineClassPopup()}>
        <CheckmarkIcon className="mr-2 mt-0.5" />
        <span>{'Site added successfully!'}</span>
      </div>
      <div className="mb-4 text-main-background">
        <div className="nm-glass-forms-header cursor-default text-center">
          <p>Add new site</p>
        </div>

        <label className="text-left block text-base text-gray-100">
          site url
        </label>

        <p className="outline-none rounded-3xl w-full px-4 py-2 text-primary-400 bg-gray-100 cursor-default">
          {siteUrl == '' ? <span>&nbsp;</span> : siteUrl}
        </p>

        <label className="text-left block text-base text-gray-100">
          site name
        </label>

        <p className="outline-none rounded-3xl w-full px-4 py-2 text-primary-400 bg-gray-100 cursor-default">
          {siteName == '' ? <span>&nbsp;</span> : siteName}
        </p>
      </div>
      <div className="mt-7">
        <div className="text-center">
          <button disabled={true} className={determineClassAddSite()}>
            Add new site
          </button>
        </div>
      </div>
    </div>
  );
};
