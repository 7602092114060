import * as Yup from 'yup';
import * as errors from '../../errors';

export const discordFormValidationSchema = Yup.object({
  discordWebhook: Yup.string()
    .required(errors.REQUIRED_FIELD)
    .matches(
      /https:\/\/discord\.com\/api\/webhooks\/[0-9]+\/.*/,
      errors.INVALID_DISCORD_WEBHOOK_FORMAT,
    ),
}).defined();
