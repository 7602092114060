import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HelmetComponent from '../../components/HelmetComponent';
import Notifications from './Notifications';
import MyAccount from './MyAccount';

export default function AccountSettings() {
  return (
    <div>
      <HelmetComponent
        title="Settings | Notify Me"
        description="Settings page."
        canonicalLink="/account_settings"
      />
      <div className="w-full p-4 mt-2 text-white min-h-screen">
        <div className="">
          <Routes>
            <Route path="my_account" element={<MyAccount />} />

            <Route path="notifications" element={<Notifications />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}
