import React from 'react';
import logoImg from '../img/icons/nm_logo/main_logo_color_with_text.svg';
import { RoughNotationWrapper } from '../components/RoughNotationWrapper';

interface Props {
  siteName?: string;
}

export const EmailHeaderSection = (props: Props) => {
  return (
    <div>
      <div className="text-center text-white">
        <img
          className="flex m-auto"
          width={64}
          height={64}
          src={logoImg}
          alt="logo"
        />
        <div className="p-2">
          <p className="text-main-letters">
            Site changed:{' '}
            <span className="underline text-white">
              {props.siteName ? props.siteName : 'my-cool-site'}
            </span>
          </p>
          <div className="text-left text-xs">
            <p>
              <span className="text-scraper-added text-base mr-1">&#9679;</span>
              This is newly added text
            </p>
            <p>
              <span className="text-scraper-removed text-base mr-1">
                &#9679;
              </span>
              This text is now removed
            </p>
            <p>
              <span className="text-scraper-changed text-base mr-1">
                &#9679;
              </span>
              <span>This text was modified</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const IntuitiveNotificationsAnimation = () => {
  const ModifiedSection = () => {
    return (
      <div className="text-left text-sm bg-main-backgroundFooter border border-white rounded-3xl text-white m-4 p-4 ">
        <EmailHeaderSection siteName="ACDC Tour" />
        <div className="border-b border-scraper-changed text-scraper-changed">
          <p>Modified:</p>
        </div>
        <div className="grid grid-cols-2 mt-2 p-1 text-sm">
          <div className="p-0.5">
            <p className="font-bold mb-4">Old content:</p>
            <p className="p-0.5 mt-2">{'Seville, Spain'}</p>
            <p className="rounded p-0.5 mt-2 bg-scraper-changed">
              <span className=" p-0.5 text-main-background">
                Munich, Germany
              </span>
            </p>
            <p className="p-0.5 mt-2">Vienna, Austria</p>
          </div>
          <div className="p-0.5">
            <p className="font-bold mb-4">New content:</p>
            <p className="p-0.5 mt-2">{'Seville Spain'}</p>
            <p className="rounded p-0.5 mt-2 bg-scraper-added">
              <span className=" p-0.5 text-main-background">
                Munich, Germany - SOLD OUT
              </span>
            </p>
            <p className="p-0.5 mt-2">Vienna, Austria</p>
          </div>
        </div>
      </div>
    );
  };

  const AddedSection = () => {
    return (
      <div className="text-left text-sm bg-main-backgroundFooter border border-white rounded-3xl text-white m-4 p-4 ">
        <EmailHeaderSection siteName="Apartments For Rent" />
        <div className="border-b border-scraper-added text-scraper-added">
          <p>Added:</p>
        </div>
        <div className="mt-2 p-1 text-sm">
          <div className="p-0.5">
            <p className="font-bold mb-4">New content:</p>
            <p className="p-0.5 mt-2">2 bedroom aparment downtown ($750)</p>
            <p className="rounded p-0.5 mt-2 bg-scraper-added ">
              <span className="p-0.5 text-main-background">
                1 bedroom apartment uptown - available immediately ($500)
              </span>
            </p>
            <p className="p-0.5 mt-2">
              House for rent near city centre ($1250)
            </p>
          </div>
        </div>
      </div>
    );
  };

  const RemovedSection = () => {
    return (
      <div className="text-left text-sm bg-main-backgroundFooter border border-white rounded-3xl text-white m-4 p-4 ">
        <EmailHeaderSection siteName="Public Works" />
        <div className="border-b border-scraper-removed text-scraper-removed">
          <p>Removed:</p>
        </div>
        <div className="grid mt-2 p-1 text-sm">
          <div className="p-0.5">
            <p className="font-bold mb-4">New content:</p>
            <p className="p-0.5 mt-2">Contact</p>
            <p className="rounded p-0.5 mt-2 bg-scraper-removed ">
              <span className="p-0.5 text-main-background">
                Planned works for 2023
              </span>
            </p>
            <p className="p-0.5 mt-2">File a complaint</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="lg:grid lg:grid-cols-3">
      <div>
        <p className="font-heading text-2xl text-center">
          <RoughNotationWrapper text="Addition" color="#2ee0bc" padding={5} />
        </p>
        <AddedSection />
      </div>
      <div>
        <p className="font-heading text-2xl text-center">
          <RoughNotationWrapper
            text="Modification"
            color="#e0bc2e"
            padding={5}
          />
        </p>
        <ModifiedSection />
      </div>
      <div>
        <p className="font-heading text-2xl text-center">
          <RoughNotationWrapper text="Deletion" color="#ff7c80" padding={5} />
        </p>
        <RemovedSection />
      </div>
    </div>
  );
};
