import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function TestSection() {
  const navigate = useNavigate();

  return (
    <div className="mt-4 p-4 m-auto max-w-5xl">
      <div>
        <div className="my-8">
          <p className="text-center text-3xl lg:text-5xl xl:text-6xl font-heading p-4 mt-8">
            Easy to Use Website Monitoring Tool
          </p>
          <p className="text-xl xl:text-2xl text-center mt-8">
            Notify Me is a simple yet powerful website monitoring tool
            specifically designed to be easy to use. <br />
            Get started in minutes with 3 simple steps.
          </p>
        </div>
        <div className="mx-auto md:grid md:gap-28 md:grid-cols-3 text-center">
          <div className="mt-4 p-4">
            <p className="font-heading text-main-letters text-3xl">Add Site</p>
            <p className="mt-2">
              Sign up and add your favorite site&apos;s URL.
            </p>
          </div>
          <div className="mt-4 p-4">
            <p className="font-heading text-main-letters text-3xl">
              We Track It
            </p>
            <p className="mt-2">
              We monitor it for changes (see{' '}
              <Link to="/pricing/" className="underline hover:no-underline">
                plans
              </Link>{' '}
              for how often)
            </p>
          </div>
          <div className="mt-4 p-4">
            <p className="font-heading text-main-letters text-3xl">
              You Get Notified
            </p>
            <p className="mt-2">We notify you when something changes</p>
          </div>
        </div>
      </div>
      <div className="flex m-auto my-8">
        <div className="m-auto">
          <button
            className="btn btn-primary btn-wide"
            onClick={() => navigate('/signup')}
          >
            Get Started Today
          </button>
          <p className="text-center text-xs mt-2 cursor-pointer">
            No credit card required
          </p>
        </div>
      </div>
    </div>
  );
}
