import * as Yup from 'yup';
import * as errors from '../../errors';

export const addNewSiteValidationSchema = Yup.object({
  siteUrl: Yup.string()
    .required(errors.REQUIRED_FIELD)
    .matches(/.*\.[a-z]{2,}/, errors.INVALID_URL_FORMAT),
  siteName: Yup.string().required(errors.REQUIRED_FIELD),
  keywords: Yup.string().max(
    100,
    'Keywords cannot be longer than 100 characters.',
  ),
}).defined();
