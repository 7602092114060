import { getEmailFromToken } from '../auth';
import * as api from '../api';

async function GetPlanUrl(planId) {
  const testPlanStagingUrl = `https://notifymeapp.lemonsqueezy.com/checkout/buy/6c63150b-21b5-4e3c-b527-0de9d143f06c?checkout[email]=${encodeURIComponent(getEmailFromToken())}`;
  const testPlanProductionUrl = `https://notifymeapp.lemonsqueezy.com/checkout/buy/6c2950b7-528b-4299-80b0-8dfcab2a8c42?checkout[email]=${encodeURIComponent(getEmailFromToken())}`;

  const standardPlanStagingUrl = `https://notifymeapp.lemonsqueezy.com/checkout/buy/778dc0b4-bc5c-4402-b18e-651b78c2429d?checkout[email]=${encodeURIComponent(getEmailFromToken())}`;
  const standardPlanProductionUrl = `https://notifymeapp.lemonsqueezy.com/checkout/buy/2dc45e79-4a91-41f8-a722-80a4c53bb0d6?checkout[email]=${encodeURIComponent(getEmailFromToken())}`;

  const lifetimePlanStagingUrl = `https://notifymeapp.lemonsqueezy.com/buy/69419c18-fea3-4a11-b658-f62f2e43dde8?checkout[email]=${encodeURIComponent(getEmailFromToken())}`;
  const lifetimePlanProductionUrl = `https://notifymeapp.lemonsqueezy.com/buy/4774264f-7749-4d2c-a5b8-83343e92f852?checkout[email]=${encodeURIComponent(getEmailFromToken())}`;

  const res = await api.isDevUser();
  const isDevUser = res.ok;

  if (isDevUser && process.env.REACT_APP_ENV !== 'local') {
    if (process.env.REACT_APP_ENV !== 'production') {
      return testPlanStagingUrl;
    }
    return testPlanProductionUrl;
  }

  if (planId === 'standard-1') {
    if (process.env.REACT_APP_ENV !== 'production') {
      return standardPlanStagingUrl;
    }
    return standardPlanProductionUrl;
  }

  if (planId === 'lifetime-1') {
    if (process.env.REACT_APP_ENV !== 'production') {
      return lifetimePlanStagingUrl;
    }
    return lifetimePlanProductionUrl;
  }
}

export async function GetStandardPlanUrl() {
  return GetPlanUrl('standard-1');
}

export function GetLifetimePlanUrl() {
  return GetPlanUrl('lifetime-1');
}
