import { useFormik } from 'formik';
import React, { useState } from 'react';
import LoadingBar from '../../../components/LoadingBar';
import { discordFormValidationSchema } from '../validation';
import * as api from '../../../api';
import { EventType, registerEvent } from '../../../plausible';
import useApiCall from '../../../hooks/UseApiCall';
import { Link } from 'react-router-dom';
import InputField from '../../../components/InputField';

interface ValuesType {
  discordWebhook: string;
}

interface DiscordData {
  webhook: string;
  enabled: boolean;
}

interface DiscordPopupProps {
  discordData: DiscordData;
  shouldRender: boolean;
  handleReRender: Function;
  isFree: boolean;
}

export default function DiscordPopup(props: DiscordPopupProps) {
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(false);

  const apiCall = useApiCall(setWaitingForServerResponse);

  const determineButton = () => {
    if (props.discordData.enabled) {
      return (
        <button
          className="btn btn-block btn-error text-white"
          onClick={async () => {
            await apiCall(
              api.deleteDiscordData(),
              'Discord removed succesfully.',
            );
            formikDiscord.setFieldValue('discordWebhook', '');
            formikDiscord.touched.discordWebhook = false;
            registerEvent(EventType.DISCORD_DELETE_WEBHOOK);
            props.handleReRender();
          }}
        >
          Remove Discord
        </button>
      );
    }
    return (
      <button className="btn btn-wide btn-primary text-white" type="submit">
        Update Discord
      </button>
    );
  };

  const formikDiscord = useFormik({
    initialValues: {
      discordWebhook: props.discordData.webhook,
    },
    enableReinitialize: true,
    validationSchema: discordFormValidationSchema,
    onSubmit: async (values: ValuesType) => {
      await apiCall(
        api.updateDiscordData(values.discordWebhook),
        'Update successful',
      );
      props.handleReRender();
    },
  });

  if (props.isFree) {
    return (
      <div className="bg-main-backgroundFooter border-2 border-main-letters p-10 text-white rounded-xl m-auto max-w-xl">
        <p className="mb-4 text-xl block">Discord notifications</p>
        <p className="mb-4 text-sm">
          This feature is not available on your plan.
        </p>
        <p className="mb-4 text-sm">
          You can upgrade your plan{' '}
          <Link to="/pricing">
            <span className="underline hover:no-underline cursor-pointer hover:text-white">
              here.
            </span>
          </Link>
        </p>
      </div>
    );
  }

  return (
    <div className="bg-main-backgroundFooter border-2 border-main-letters p-10 text-white rounded-xl m-auto max-w-xl">
      <div className="text-left">
        <p className="mb-4 m-auto text-xl">Discord notifications</p>
        <p className="mb-4 m-auto text-sm">
          Not sure what to do?&nbsp;
          <a
            className="underline"
            href="https://support.discord.com/hc/en-us/articles/228383668-Intro-to-Webhooks"
            target="_blank"
            rel="noopener noreferrer"
          >
            See the official docs.
          </a>
        </p>
      </div>
      {waitingForServerResponse ? (
        <div className="m-auto text-center mt-20">
          <LoadingBar isLoading={true} />
        </div>
      ) : (
        <div className="w-full mt-8">
          <form onSubmit={formikDiscord.handleSubmit}>
            <div className="flex">
              <div className="w-full">
                <InputField
                  label="discord webhook"
                  id="discordWebhook"
                  type="discordWebhook"
                  name="discordWebhook"
                  value={formikDiscord.values.discordWebhook}
                  onChange={formikDiscord.handleChange}
                  onBlur={formikDiscord.handleBlur}
                  error={
                    formikDiscord.touched.discordWebhook &&
                    formikDiscord.errors.discordWebhook
                      ? formikDiscord.errors.discordWebhook
                      : undefined
                  }
                />
              </div>
              <div
                className={
                  props.discordData.enabled
                    ? 'hidden'
                    : '' +
                      'text-red-600 my-auto sm:text-gray-600 ml-4 w-8  transform hover:text-red-600 hover:scale-110 cursor-pointer'
                }
                onClick={async () => {
                  formikDiscord.setFieldValue('discordWebhook', '');
                  formikDiscord.touched.discordWebhook = false;
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </div>
            </div>
            <div className="mt-4 flex">{determineButton()}</div>
          </form>
        </div>
      )}
    </div>
  );
}
