import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useApiCall from '../../../hooks/UseApiCall';
import * as api from '../../../api';
import LoadingBar from '../../../components/LoadingBar';
import { toast } from 'react-hot-toast';

interface TelegramData {
  token: string;
  enabled: boolean;
}
interface TelegramPopupProps {
  telegramData: TelegramData;
  shouldRender: boolean;
  handleReRender: Function;
  isFree: boolean;
}

export default function TelegramPopup(props: TelegramPopupProps) {
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(false);
  const apiCall = useApiCall(setWaitingForServerResponse);

  useEffect(() => {
    (async () => {
      if (props.isFree) {
        return;
      }
    })();
  });

  const determineTelegramBot = () => {
    let notifyMeBotName = 'notify_me_app_local_bot';
    if (process.env.REACT_APP_ENV === 'staging') {
      notifyMeBotName = 'notify_me_app_staging_bot';
    }
    if (process.env.REACT_APP_ENV === 'production') {
      notifyMeBotName = 'notify_me_app_bot';
    }

    return `https://t.me/${notifyMeBotName}?start=${props.telegramData.token}`;
  };

  const onFinish = async () => {
    const response = await apiCall(api.getTelegramData(), undefined);
    const telegramData = response.data as TelegramData;

    if (!telegramData.enabled) {
      toast.error(
        "You haven't set up Telegram notifications yet. Please set them up first.",
      );
      return;
    }
    toast.success('Telegram notifications set up successfully.');

    props.handleReRender();
    return;
  };

  const onRemove = async () => {
    const response = await apiCall(
      api.removeTelegramNotifications(),
      'Telegram notifications removed successfully.',
    );
    if (response.error) {
      return;
    }
    props.handleReRender();
    return;
  };

  if (props.isFree) {
    return (
      <div className="bg-main-backgroundFooter border-2 border-main-letters p-10 text-white rounded-xl m-auto max-w-xl">
        <p className="mb-4 text-xl block">Telegram notifications</p>
        <p className="mb-4 text-sm">
          This feature is not available on your plan.
        </p>
        <p className="mb-4 text-sm">
          You can upgrade your plan{' '}
          <Link to="/pricing">
            <span className="underline hover:no-underline cursor-pointer hover:text-white">
              here.
            </span>
          </Link>
        </p>
      </div>
    );
  }

  if (props.telegramData.enabled) {
    return (
      <div className="bg-main-backgroundFooter border-2 border-main-letters p-10 text-white rounded-xl m-auto max-w-xl">
        <div>
          <p className="mb-4 text-xl block">
            Telegram notifications are up and running!
          </p>
          <div>
            <button
              className="btn btn-block btn-error text-white"
              onClick={onRemove}
            >
              Remove Telegram notifications
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-main-backgroundFooter border-2 border-main-letters p-10 text-white rounded-xl m-auto max-w-xl">
      <div>
        <p className="mb-4 text-xl block">Telegram notifications</p>
        <p className="mb-4 text-sm">1. Open the Telegram App</p>
        <p className="mb-4 text-sm">
          2. Start a chat with{' '}
          <a
            href={determineTelegramBot()}
            target="_blank"
            rel="noopener noreferrer"
            className="underline hover:no-underline font-bold"
          >
            @NotifyMeBot
          </a>
        </p>
        <p className="my-4 text-sm">
          3. If you don&apos;t get confirmation message immediately - Send the
          following code into the chat so that @NotifyMeBot can send you
          notifications:
          <br />
          <span className="font-bold text-main-letters">
            {props.telegramData.token}
          </span>
        </p>
        <p className="mb-4 text-sm">
          4. Click the Finish button after you hear back from @NotifyMeBot
        </p>
      </div>
      {waitingForServerResponse ? (
        <div className="text-center">
          <LoadingBar isLoading={true} />
        </div>
      ) : (
        <div>
          <button
            className="btn btn-block btn-primary text-white"
            onClick={onFinish}
          >
            Finish
          </button>
        </div>
      )}
      <div></div>
    </div>
  );
}
