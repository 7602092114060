/* eslint-disable no-unused-vars */
import React from 'react';
import {
  EssentialsPlan,
  LifetimePlan,
  StandardPlan,
  StarterPlan,
} from './Plans';
import { BillingInfo, PlanId } from '../../types';
import LoadingBar from '../../components/LoadingBar';

export default function Plans(props: { billingInfo: BillingInfo }) {
  if (!props.billingInfo) {
    return (
      <div className="m-auto text-center">
        <LoadingBar isLoading={true} />
      </div>
    );
  }

  if (props.billingInfo.planIs(PlanId.LIFETIME)) {
    return <></>;
  }

  if (props.billingInfo.id === PlanId.ESSENTIALS) {
    return (
      <div className="p-4 mt-4">
        <div className="flex m-auto justify-center">
          <div className="sm:grid sm:grid-cols-3 sm:gap-12">
            <LifetimePlan billingInfo={props.billingInfo} />
            <EssentialsPlan billingInfo={props.billingInfo} />
            <StarterPlan billingInfo={props.billingInfo} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 mt-4">
      <div className="flex m-auto justify-center">
        <div className="sm:grid sm:grid-cols-3 sm:gap-12">
          <LifetimePlan billingInfo={props.billingInfo} />
          <StandardPlan billingInfo={props.billingInfo} />
          <StarterPlan billingInfo={props.billingInfo} />
        </div>
      </div>
    </div>
  );
}
