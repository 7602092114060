import React, { useState } from 'react';
import * as api from '../../api';
import LoadingBar from '../../components/LoadingBar';
import { EventType, registerEvent } from '../../plausible';
import HelmetComponent from '../../components/HelmetComponent';
import { useFormik } from 'formik';
import { sendReviewValidationSchema } from './validation';
import TextArea from '../../components/TextArea';
import useApiCall from '../../hooks/UseApiCall';

export default function SendReview() {
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(false);

  const apiCall = useApiCall(setWaitingForServerResponse);

  const formik = useFormik({
    initialValues: {
      review: '',
    },
    validationSchema: sendReviewValidationSchema,
    onSubmit: async (values) => {
      onSubmit(values.review);
    },
  });

  const onSubmit = async (review: string) => {
    await apiCall(api.sendReview(review), 'Review sent successfully.');

    registerEvent(EventType.SEND_REVIEW);
  };

  return (
    <div className="">
      <HelmetComponent
        title="Send Review | Notify Me"
        description="Send review from here."
        canonicalLink="/send_review"
      />
      <div className="flex lg:min-h-screen justify-center items-center">
        <form
          className="bg-main-footerLinks bg-opacity-40 p-8 border rounded-xl border-main-letters max-w-sm w-full"
          onSubmit={formik.handleSubmit}
        >
          <div>
            <h1 className="my-4 lg:my-8 font-bold font-heading text-4xl text-main-letters text-center">
              Send Review
            </h1>
          </div>
          <TextArea
            rows={8}
            placeholder="Write anything that you can think could make this app better."
            label=""
            id="review"
            name="review"
            value={formik.values.review}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.review && formik.errors.review
                ? formik.errors.review
                : undefined
            }
          />
          <div></div>
          <button className="btn btn-block btn-primary" type="submit">
            Send Review
          </button>
          <div className="text-center">
            <div className="mt-4 ">
              <LoadingBar isLoading={waitingForServerResponse} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
