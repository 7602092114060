import * as Yup from 'yup';
import * as errors from '../../errors';

export const logInValidationSchema = Yup.object({
  email: Yup.string()
    .email(errors.INVALID_EMAIL_FORMAT)
    .required(errors.REQUIRED_FIELD),
  password: Yup.string()
    .min(8, errors.INVALID_PASSWORD_FORMAT)
    .max(30, errors.INVALID_PASSWORD_FORMAT)
    .required(errors.REQUIRED_FIELD),
}).defined();
