import React from 'react';
import Sidebar from '../Sidebar';

export default function SidebarWrapper({ children }) {
  return (
    <>
      <div className="lg:grid lg:grid-cols-12">
        <div className="col-span-2">
          <Sidebar />
        </div>
        <div className="col-span-9 col-start-3 lg:ml-8">{children}</div>
      </div>
    </>
  );
}
