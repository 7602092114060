import React from 'react';
import * as errors from '../../errors';
import * as api from '../../api';
import { useAuth } from '../../context/AuthContext';
import { EventType, registerEvent } from '../../plausible';
import { useGoogleLogin } from '@react-oauth/google';
import googleImg from '../../img/icons/SVG/google_icon.svg';
import useApiCall from '../../hooks/UseApiCall';
import { useNavigate } from 'react-router-dom';

interface Props {
  setErrorMessage: Function;
  setWaitingForServerResponse: Function;
  isSignupFlow: boolean;
}

export default function GoogleOAuthComponent(props: Props) {
  const authContext = useAuth();
  const apiCall = useApiCall(
    props.setWaitingForServerResponse as (_: boolean) => void,
  );
  const navigate = useNavigate();

  const responseGoogle = async (access_token: string) => {
    const response = await apiCall(api.googleOAuth(access_token), undefined);
    if (response.error) {
      props.setErrorMessage(errors.GOOGLE_OAUTH_ERROR);
      return;
    }
    registerEvent(EventType.SIGNUP_GOOGLE_OAUTH);
    authContext.loginUser(response.data.access_token);
  };

  const login = useGoogleLogin({
    onSuccess: async (res) => {
      await responseGoogle(res.access_token);
      if (props.isSignupFlow) {
        navigate('/add_new_site');
      }
    },
  });

  return (
    <button
      onClick={() => login()}
      className="flex m-auto p-4 rounded-xl bg-black bg-opacity-10 focus:outline-none text-sm hover:bg-opacity-20 disabled:text-main-footerLinks disabled:hover:bg-opacity-10"
    >
      <div className="flex justify-center">
        <img width={20} height={20} src={googleImg} alt="google logo" />
        <p className="ml-2 text-sm">Continue with Google</p>
      </div>
    </button>
  );
}
