import React from 'react';
import { useNavigate } from 'react-router-dom';

import vanjaPaunovic from './testimonialImg/vanja_paunovic.jpg';
import lazarPopovic from './testimonialImg/lazar_popovic.jpg';
import anaGraovac from './testimonialImg/ana_graovac.jpg';
import milosMilunovic from './testimonialImg/milos_milunovic.jpg';
import jelenaGledic from './testimonialImg/jelena_gledic.png';
import ctrlalt from './testimonialImg/ctralalt.png';

import { FaStar } from 'react-icons/fa';
import { RoughNotationWrapper } from '../../components/RoughNotationWrapper';

export default function MainSection() {
  const navigate = useNavigate();
  return (
    <div className="flex p-4 xl:p-24 bg-main-test1">
      <div className="m-auto p-4">
        <div className="lg:max-w-4xl xl:max-w-6xl">
          <h1 className="m-auto font-heading text-5xl lg:text-7xl xl:text-8xl text-center text-main-background">
            Get{' '}
            <span>{<RoughNotationWrapper text="alerts" padding={5} />}</span>{' '}
            when a web page changes.
          </h1>
          <h2 className="text-xl lg:text-2xl xl:text-3xl text-center mt-8 lg:mt-12 text-main-background">
            Notify Me is a simple{' '}
            <span className="font-black">
              {<RoughNotationWrapper text="web page monitoring" padding={5} />}
            </span>
            <span>&nbsp;&nbsp;and&nbsp;</span>{' '}
            <span className="font-black">
              {<RoughNotationWrapper text="tracking" padding={5} />}
            </span>{' '}
            tool that will send you updates when a web page you are interested
            in changes. Track <span className="font-black">job postings</span>,{' '}
            <span className="font-black">product prices</span>,{' '}
            <span className="font-black">news articles</span>, and more.
          </h2>

          <div className="flex mt-4">
            <div className="flex m-auto">
              <a href="https://www.uneed.best/tool/notify-me" target="blank">
                <img
                  src="https://www.uneed.best/POTD1.png"
                  alt="Uneed POTD1 Badge"
                  className="w-36 md:mx-4"
                />
              </a>
              <a href="https://www.uneed.best/tool/notify-me" target="blank">
                <img
                  src="https://www.uneed.best/POTW2.png"
                  alt="Uneed POTW2 Badge"
                  className="w-40 md:mx-4"
                />
              </a>
            </div>
          </div>
          <div className="flex mt-4">
            <div className="flex m-auto">
              <a href="https://ctrlalt.cc/product/notify-me" target="blank">
                <img
                  src={ctrlalt}
                  alt="Ctrlalt product of the day badge"
                  className="w-16 lg:w-20"
                />
              </a>
            </div>
          </div>
          <div className="m-auto mt-6 max-w-2xl">
            <button
              className="btn btn-primary btn-block btn-lg"
              onClick={() => navigate('/signup')}
            >
              <span className="text-xl">Start Tracking Now</span>
            </button>
            <p
              className="text-center text-xs text-main-background mt-2 cursor-pointer"
              onClick={() => navigate('/pricing')}
            >
              Get a Free forever Plan
            </p>
          </div>
        </div>
        <div className="">
          <p className="text-main-background text-center text-xl lg:text-2xl xl:text-3xl mt-8 lg:mt-12">
            Trusted by <strong>3873+ users</strong> who are getting
            notifications when their favorite sites change.
          </p>
          <div className="flex">
            <div className="m-auto">
              <div className="flex mt-4">
                <img
                  src={vanjaPaunovic}
                  className="w-12 h-12 lg:w-16 lg:h-16 xl:w-20 xl:h-20 rounded-full border-2 border-main-background z-50"
                />
                <img
                  src={milosMilunovic}
                  className="w-12 h-12 lg:w-16 lg:h-16 xl:w-20 xl:h-20 rounded-full border-2 border-main-background z-40 -ml-4"
                />
                <img
                  src={anaGraovac}
                  className="w-12 h-12 lg:w-16 lg:h-16 xl:w-20 xl:h-20 rounded-full border-2 border-main-background z-30 -ml-4"
                />
                <img
                  src={lazarPopovic}
                  className="w-12 h-12 lg:w-16 lg:h-16 xl:w-20 xl:h-20 rounded-full border-2 border-main-background z-20 -ml-4"
                />
                <img
                  src={jelenaGledic}
                  className="w-12 h-12 lg:w-16 lg:h-16 xl:w-20 xl:h-20 rounded-full border-2 border-main-background z-10 -ml-4"
                />
                <div className="flex text-yellow-400 my-auto mr p-4">
                  <FaStar size={24} className="mx-0.5" />
                  <FaStar size={24} className="mx-0.5" />
                  <FaStar size={24} className="mx-0.5" />
                  <FaStar size={24} className="mx-0.5" />
                  <FaStar size={24} className="mx-0.5" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
