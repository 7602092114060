import React, { useState } from 'react';
import { EventType, registerEvent } from '../../../plausible';
import Popup from 'reactjs-popup';
import DeletePopup from './DeletePopup';

interface Props {
  onDelete: Function;
  onEdit: Function;
  siteId: string;
}

export default function Actions(props: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const deleteSite = () => {
    setIsOpen(false);
    props.onDelete(props.siteId);
    registerEvent(EventType.DELETE_SITE);
  };

  return (
    <div className="flex justify-center">
      <div
        className="w-4 mr-5  text-dark-contrast sm:text-gray-600 transform hover:text-dark-contrast hover:scale-110 cursor-pointer"
        onClick={() => {
          props.onEdit(props.siteId);
          registerEvent(EventType.EDIT_SITE_JUST_CLICK);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="tooltip-edit"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
          />
        </svg>
      </div>
      <div
        className="text-red-600 sm:text-gray-600 w-4  transform hover:text-red-600 hover:scale-110 cursor-pointer"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="tooltip-delete"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
          />
        </svg>
        <Popup
          modal
          overlayStyle={{ background: 'rgba(0,0,0,0.5)' }}
          open={isOpen}
        >
          <DeletePopup setIsOpen={setIsOpen} deleteSite={deleteSite} />
        </Popup>
      </div>
    </div>
  );
}
