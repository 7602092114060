import React from 'react';
import { Navigate } from 'react-router-dom';
import * as auth from '../../auth';

export default function RedirectIfLoggedInRoute({ children }) {
  const params = new URLSearchParams(window.location.search);
  const packageName = params.get('package');

  if (!auth.isAuthenticated()) {
    return children;
  }

  if (packageName != null && packageName != '') {
    return <Navigate to={`/add_package?package=${packageName}`} />;
  }

  return <Navigate to="/overview" />;
}
