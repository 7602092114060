import React from 'react';
import HelmetComponent from '../../components/HelmetComponent';
import { PlansLoggedOut } from './PlansLoggedOut';
import * as auth from '../../auth';
import BillingAndPlans from './BillingAndPlans';

export default function Pricing() {
  return (
    <div className="">
      <HelmetComponent
        title="Pricing | Notify Me"
        description="Pick pricing plan that's right for you - or start monitoring sites for free. No credit card required, cancel anytime."
        canonicalLink="/pricing"
      />
      {auth.isAuthenticated() ? (
        <div className="lg:min-h-screen">
          <BillingAndPlans />
        </div>
      ) : (
        <div className="lg:min-h-screen">
          <PlansLoggedOut />
        </div>
      )}
    </div>
  );
}
