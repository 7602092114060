import React from 'react';
import { useNavigate } from 'react-router-dom';
import { EmailExampleWrapper } from '../../components/EmailExample';
import HelmetComponent from '../../components/HelmetComponent';
import LinkButton from '../../components/LinkButton';

export default function HowItWorks() {
  const navigate = useNavigate();

  return (
    <div className="">
      <HelmetComponent
        title="How It Works | Notify Me"
        description="Set up sites you want to track in less than 5 minutes. Get started with Notify Me for free. No credit card required."
        canonicalLink="/how_it_works"
      />
      <div className="p-2 lg:p-5">
        <div className="p-8">
          <h1 className="nm-default-page-heading">How It Works?</h1>
        </div>
      </div>

      <div className="text-left max-w-2xl m-auto">
        <div className="p-8">
          <p className="text-xl">1. Getting started</p>
          <p className="text-base mt-4">
            This page assumes that you&apos;ve already created your account. If
            you haven&apos;t, you can do that right{' '}
            <LinkButton onClick={() => navigate('/signup')} label="here" /> - or
            don&apos;t; who are we to tell you what to do &#129335;.
          </p>
        </div>

        <div className="p-8">
          <p className="text-xl mt-4">2. Adding site</p>
          <p className="mt-4">
            Let&apos;s say that you want to track{' '}
            <a
              href="https://time.is/"
              target="_blank"
              rel="noopener noreferrer"
              className="underline hover:no-underline"
            >
              time.is
            </a>{' '}
            - pretty straightforward, right?
          </p>
          <p className="my-4">
            Just click the &quot;Add new site&quot; button and fill out the
            form:
          </p>
          <div className="nm-glass-forms-box-wrapper  max-w-sm m-auto relative">
            <div className="mb-4 text-main-background">
              <div className="nm-glass-forms-header cursor-default text-center">
                <p>Add new site</p>
              </div>
              <label className="text-left block text-base text-gray-100">
                site url
              </label>
              <p className="outline-none rounded-3xl w-full px-4 py-2 bg-gray-100 cursor-default">
                time.is
              </p>
              <label className="text-left block text-base text-gray-100">
                site name
              </label>
              <p className="outline-none rounded-3xl w-full px-4 py-2 bg-gray-100 cursor-default">
                exact time
              </p>
            </div>
            <div className="mt-4">
              <input
                type="checkbox"
                id="checkbox"
                className="mr-4"
                disabled={true}
              />
              <label htmlFor="checkbox">
                I want to track only changes with specific words
              </label>
            </div>
            <div className="mt-7">
              <div className="text-center">
                <button
                  disabled={true}
                  className="w-full bg-main-letters rounded-3xl text-main-purple font-bold p-2.5"
                >
                  Add new site
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="p-8">
          <p className="text-xl mt-4">3. Tracking specific changes</p>
          <p className="my-4">
            If you are interested only in specific changes (e.g., you only want
            notifications when the month changes), check the &quot;I want to
            track only changes with specific words&quot; option.
          </p>
          <p className="my-4">
            This way, you&apos;ll only receive notifications about changes that
            contain those words. Everything else will be ignored.
          </p>
          <div className="nm-glass-forms-box-wrapper max-w-sm m-auto relative">
            <div className="mb-4 text-main-background">
              <div className="nm-glass-forms-header cursor-default text-center">
                <p>Add new site</p>
              </div>
              <label className="text-left block text-base text-gray-100">
                site url
              </label>
              <p className="outline-none rounded-3xl w-full px-4 py-2 bg-gray-100 cursor-default">
                time.is
              </p>
              <label className="text-left block text-base text-gray-100">
                site name
              </label>
              <p className="outline-none rounded-3xl w-full px-4 py-2 bg-gray-100 cursor-default">
                exact time with specific month
              </p>
            </div>
            <div className="mt-4">
              <input
                type="checkbox"
                id="checkbox"
                className="mr-4"
                checked={true}
                readOnly={true}
              />
              <label htmlFor="checkbox">
                I want to track only changes with specific words
              </label>
            </div>
            <div className="mt-4">
              <textarea
                className="outline-none rounded-3xl w-full px-4 py-2 text-black bg-gray-100"
                rows={2}
                readOnly={true}
                value="january"
              />
            </div>
            <div className="mt-7">
              <div className="text-center">
                <button
                  disabled={true}
                  className="w-full bg-main-letters rounded-3xl text-main-purple font-bold p-2.5"
                >
                  Add new site
                </button>
              </div>
            </div>
          </div>
          <div>
            <p className="my-4">
              In this example, you&apos;ll only get a notification when the
              month changes to January, which can be quite a wait, depending on
              the time of year you are reading this.
            </p>
            <p className="my-4">
              To track multiple specific changes, just put more words into the
              text box:
            </p>
            <div className="nm-glass-forms-box-wrapper max-w-sm m-auto relative">
              <div className="mb-4 text-main-background">
                <div className="nm-glass-forms-header cursor-default text-center">
                  <p>Add new site</p>
                </div>
                <label className="text-left block text-base text-gray-100">
                  site url
                </label>
                <p className="outline-none rounded-3xl w-full px-4 py-2 bg-gray-100 cursor-default">
                  time.is
                </p>
                <label className="text-left block text-base text-gray-100">
                  site name
                </label>
                <p className="outline-none rounded-3xl w-full px-4 py-2 bg-gray-100 cursor-default">
                  exact time with more months
                </p>
              </div>
              <div className="mt-4">
                <input
                  type="checkbox"
                  id="checkbox"
                  className="mr-4"
                  checked={true}
                  readOnly={true}
                />
                <label htmlFor="checkbox">
                  I want to track only changes with specific words
                </label>
              </div>
              <div className="mt-4">
                <textarea
                  className="outline-none rounded-3xl w-full px-4 py-2 text-black bg-gray-100"
                  rows={2}
                  readOnly={true}
                  value="january april july"
                />
              </div>
              <div className="mt-7">
                <div className="text-center">
                  <button
                    disabled={true}
                    className="w-full bg-main-letters rounded-3xl text-main-purple font-bold p-2.5"
                  >
                    Add new site
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-8">
          <p className="text-xl mt-4">4. Site statuses</p>
          <p className="my-4">
            Each time you add a new site, it will show up in the table on the
            overview page along with its status:
          </p>
          <div className=" max-w-xl bg-white shadow-2xl rounded my-6 overflow-x-auto m-auto">
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-200 text-gray-600 text-xs md:text-sm leading-normal">
                  <th className="p-3 md:py-3 md:px-6 text-left">Name</th>
                  <th className="p-3 md:py-3 md:px-6 text-center">Url</th>
                  <th className="p-3 md:py-3 md:px-6 text-center">Status</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-xs md:text-sm font-light">
                <tr className="border-b border-gray-200">
                  <td className="p-3 md:py-3 md:px-6 text-left">
                    <div className="flex items-center">
                      <span>exact time</span>
                    </div>
                  </td>
                  <td className="p-3 md:py-3 md:px-6 text-center flex">
                    time.is
                  </td>
                  <td className="p-3 md:py-3 md:px-6 text-center">
                    <span className="bg-green-600 px-2 py-1 md:py-1 md:px-3 rounded-full text-xs text-white">
                      Active
                    </span>
                  </td>
                </tr>
                <tr className="border-b border-gray-200">
                  <td className="p-3 md:py-3 md:px-6 text-left">
                    <div className="flex items-center">
                      <span>just added site</span>
                    </div>
                  </td>
                  <td className="p-3 md:py-3 md:px-6 text-center flex">
                    pending.com
                  </td>
                  <td className="p-3 md:py-3 md:px-6 text-center">
                    <span className="bg-yellow-600 px-2 py-1 md:py-1 md:px-3 rounded-full text-xs text-white">
                      Pending
                    </span>
                  </td>
                </tr>
                <tr className="border-b border-gray-200">
                  <td className="p-3 md:py-3 md:px-6 text-left">
                    <div className="flex items-center">
                      <span>not working </span>
                    </div>
                  </td>
                  <td className="p-3 md:py-3 md:px-6 text-center flex">
                    404.com
                  </td>
                  <td className="p-3 md:py-3 md:px-6 text-center">
                    <span className="bg-red-600 px-2 py-1 md:py-1 md:px-3 rounded-full text-xs text-white">
                      Down
                    </span>
                  </td>
                </tr>
                <tr className="border-b border-gray-200">
                  <td className="p-3 md:py-3 md:px-6 text-left">
                    <div className="flex items-center">
                      <span>...</span>
                    </div>
                  </td>
                  <td className="p-3 md:py-3 md:px-6 text-center flex">...</td>
                  <td className="p-3 md:py-3 md:px-6 text-center">...</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="my-4">
            What&apos;s a status, you may wonder? - That&apos;s just a way for
            us to show what&apos;s happening with the site:
          </p>
          <ul className="list-none list-inside my-8 p-4 bg-main-footerLinks bg-opacity-10 rounded-md text-sm">
            <li className="">
              <span className="bg-green-600 py-1 px-3 rounded-full leading-loose">
                Active
              </span>{' '}
              - The site is up and running regularly. At least, it was the last
              time we checked.
            </li>
            <li className="mt-6">
              <span className="bg-yellow-600 py-1 px-3 rounded-full leading-loose">
                Pending
              </span>{' '}
              - We still haven&apos;t reached the site, so it may be Active or
              Down; who knows - kinda like Schrodinger&apos;s cat, just with
              less fur and more HTML.
            </li>
            <li className="mt-6">
              <span className="bg-red-600 py-1 px-3 rounded-full leading-loose">
                Down
              </span>{' '}
              - The site is down. This means that we tried to access the site,
              but we failed. This may be a problem with the site, or maybe you
              just made a typo in the site&apos;s URL.
            </li>
          </ul>
          <p className="mt-4">
            We will send you an email each time the site status changes, so you
            can immediately know what&apos;s going on.
          </p>
        </div>

        <div className="p-8">
          <p className="text-xl mt-4">5. Change notification</p>
          <p className="my-4">
            You&apos;ve finally set up all your sites, and one of them actually
            changed. This means you&apos;ll get a change notification in your
            email and wherever else you&apos;ve set it up. By
            &quot;wherever&quot; we mostly mean Discord.
          </p>
          <p className="my-4">
            Please note that checks for changes are <b>periodic</b>, so
            you&apos;ll get all changes that happened in the specific time span.
            Currently most of our sites are checked approximately every 2 hours.
          </p>
          <p className="my-4">
            If you have the need for more frequent changes, don&apos;t hesitate
            to reach out{' '}
            <a
              href="mailto:tesic@notify-me.rs"
              className="underline hover:no-underline"
            >
              here
            </a>
            .
          </p>
          <p className="text-xl mt-4">5.1 Content modified</p>
          <div>
            <p className="my-4">
              Let&apos;s say that you are still tracking{' '}
              <a
                href="https://time.is/"
                target="_blank"
                rel="noopener noreferrer"
                className="underline hover:no-underline"
              >
                time.is
              </a>{' '}
              and that 24h have passed since we last checked the site.
              You&apos;ll get something similar to this:
            </p>
            <div className="max-w-2xl m-auto">
              <EmailExampleWrapper>
                <div className="text-left text-xs md:text-sm mt-2 ">
                  <div className="border-b border-scraper-changed text-scraper-changed">
                    <p>Modified:</p>
                  </div>
                  <div className="grid grid-cols-2 p-0.5 lg:p-4 mt-2 bg-[#141940]">
                    <div className="p-0.5">
                      <p className="font-bold mb-4">Old content:</p>
                      <p className="p-0.5 mt-2">
                        {"Content that hasn't changed"}
                      </p>
                      <p className="rounded p-0.5 mt-2 bg-scraper-changed">
                        <span className=" p-0.5 text-main-background">
                          Friday, October 7, 2022
                        </span>
                      </p>
                      <p className="p-0.5 mt-2">More unchanged content</p>
                    </div>
                    <div className="p-0.5">
                      <p className="font-bold mb-4">New content:</p>
                      <p className="p-0.5 mt-2">
                        {"Content that hasn't changed"}
                      </p>
                      <p className="rounded p-0.5 mt-2 bg-scraper-changed">
                        <span className=" p-0.5 text-main-background">
                          Saturday, October 8, 2022
                        </span>
                      </p>
                      <p className="p-0.5 mt-2">More unchanged content</p>
                    </div>
                  </div>
                </div>
              </EmailExampleWrapper>
            </div>
            <p className="mt-4">
              This email describes the changes that happen when the site&apos;s
              existing content is modified.
            </p>
            <ul className="list-disc list-inside p-4 ">
              <li className="">
                Changes are always highlighted with specific colors
              </li>
              <li className="mt-2">
                Some unchanged parts of the site are always present to give you
                context
              </li>
            </ul>
          </div>
          <div>
            <p className="text-xl mt-4">5.2 Content deleted</p>
            <p className="my-4">
              For the next type of our mail, we will assume that someone just
              got tired of having the quote of the day, so they just decided
              that they will remove it from now on:
            </p>
            <div className="max-w-2xl m-auto">
              <EmailExampleWrapper siteName="exact time">
                <div className="text-left text-xs md:text-sm mt-2 ">
                  <div className="border-b border-scraper-removed text-scraper-removed">
                    <p>Removed:</p>
                  </div>
                  <div className="p-0.5 lg:p-4 mt-2 bg-[#141940]">
                    <div className="p-0.5">
                      <p className="font-bold mb-4">Old content:</p>
                      <p className="p-0.5 mt-2">
                        {"Content that hasn't changed"}
                      </p>
                      <p className="rounded p-0.5 mt-2 bg-scraper-removed">
                        <span className=" p-0.5 text-white">
                          &quot;Prediction is very difficult, especially if
                          it&apos;s about the future.&quot;
                          <br />
                          Niels Bohr (7 October 1885 – 18 November 1962)
                        </span>
                      </p>
                      <p className="p-0.5 mt-2">More unchanged content</p>
                    </div>
                  </div>
                </div>
              </EmailExampleWrapper>
            </div>
          </div>
          <p className="my-4">
            Similar to the modified email, you&apos;ll always get some unchanged
            content apart from the actual change.
          </p>
          <div>
            <p className="text-xl mt-4">5.3 Content added</p>
            <p className="my-4">
              In this case, someone decided that also having a weather report
              would be a good idea, so they decided to add it to the site:
            </p>
            <div className="max-w-2xl m-auto">
              <EmailExampleWrapper siteName="exact time">
                <div className="text-left text-xs md:text-sm mt-2 ">
                  <div className="border-b border-scraper-added text-scraper-added">
                    <p>Added:</p>
                  </div>
                  <div className="p-0.5 lg:p-4 mt-2 bg-[#141940]">
                    <div className="p-0.5">
                      <p className="font-bold mb-4">Old content:</p>
                      <p className="p-0.5 mt-2">
                        {"Content that hasn't changed"}
                      </p>
                      <p className="rounded p-0.5 mt-2 bg-scraper-added">
                        <span className=" p-0.5 text-main-background">
                          Weather today sunny and beautiful, just like you!
                        </span>
                      </p>
                      <p className="p-0.5 mt-2">More unchanged content</p>
                    </div>
                  </div>
                </div>
              </EmailExampleWrapper>
            </div>
            <p className="my-4">
              Like the changes above, some parts of the unchanged content are
              always present to give you more context.
            </p>
          </div>
          <div>
            <p className="text-xl mt-4">
              5.4 Multiple types of changes at once
            </p>
            <p className="my-4">
              Maybe someone at time.is decided to kill 3 birds with 1 stone, so
              they removed the quote of the day and added a weather report all
              on the same day the date change occurred.
              <br />
            </p>
            <p className="my-4">
              The email you&apos;d get in that case would look something like
              this:
            </p>
            <div className="max-w-2xl m-auto">
              <EmailExampleWrapper siteName="exact time">
                <div className="text-left text-xs md:text-sm mt-2 ">
                  <div className="border-b border-scraper-changed text-scraper-changed">
                    <p>Modified:</p>
                  </div>
                  <div className="grid grid-cols-2 p-0.5 lg:p-4 mt-2 bg-[#141940]">
                    <div className="p-0.5">
                      <p className="font-bold mb-4">Old content:</p>
                      <p className="p-0.5 mt-2">
                        {"Content that hasn't changed"}
                      </p>
                      <p className="rounded p-0.5 mt-2 bg-scraper-changed">
                        <span className=" p-0.5 text-main-background">
                          Friday, October 7, 2022
                        </span>
                      </p>
                      <p className="p-0.5 mt-2">More unchanged content</p>
                    </div>
                    <div className="p-0.5">
                      <p className="font-bold mb-4">New content:</p>
                      <p className="p-0.5 mt-2">
                        {"Content that hasn't changed"}
                      </p>
                      <p className="rounded p-0.5 mt-2 bg-scraper-changed">
                        <span className=" p-0.5 text-main-background">
                          Saturday, October 8, 2022
                        </span>
                      </p>
                      <p className="p-0.5 mt-2">More unchanged content</p>
                    </div>
                  </div>
                </div>
                <div className="text-left text-xs md:text-sm mt-2 ">
                  <div className="border-b border-scraper-removed text-scraper-removed">
                    <p>Removed:</p>
                  </div>
                  <div className="p-0.5 lg:p-4 mt-2 bg-[#141940]">
                    <div className="p-0.5">
                      <p className="font-bold mb-4">Old content:</p>
                      <p className="p-0.5 mt-2">
                        {"Content that hasn't changed"}
                      </p>
                      <p className="rounded p-0.5 mt-2 bg-scraper-removed">
                        <span className=" p-0.5 text-white">
                          &quot;Prediction is very difficult, especially if
                          it&apos;s about the future.&quot;
                          <br />
                          Niels Bohr (7 October 1885 – 18 November 1962)
                        </span>
                      </p>
                      <p className="p-0.5 mt-2">More unchanged content</p>
                    </div>
                  </div>
                </div>
                <div className="text-left text-xs md:text-sm mt-2 ">
                  <div className="border-b border-scraper-added text-scraper-added">
                    <p>Added:</p>
                  </div>
                  <div className="p-0.5 lg:p-4 mt-2 bg-[#141940]">
                    <div className="p-0.5">
                      <p className="font-bold mb-4">Old content:</p>
                      <p className="p-0.5 mt-2">
                        {"Content that hasn't changed"}
                      </p>
                      <p className="rounded p-0.5 mt-2 bg-scraper-added">
                        <span className=" p-0.5 text-main-background">
                          Weather today sunny and beautiful, just like you!
                        </span>
                      </p>
                      <p className="p-0.5 mt-2">More unchanged content</p>
                    </div>
                  </div>
                </div>
              </EmailExampleWrapper>
            </div>
            <div>
              <p className="my-4">
                You can pretty much get any combination of changes in your
                email, even multiple ones of the same type.
              </p>
              <p className="my-4">
                That can happen if multiple parts of the site are, for example,
                removed at the same time.
              </p>
            </div>
            <div>
              <p className="mt-4">
                Hopefully, this guide helped you understand how Notify Me works.
                You know what to do next (if you already haven&apos;t) &#128071;
                <button className="btn btn-block btn-primary mt-4">
                  Sign Up - It&apos;s Free
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
