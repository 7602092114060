import React, { ReactNode } from 'react';
import logoImg from '../../img/icons/nm_logo/main_logo_color_with_text.svg';

interface Props {
  children: ReactNode;
  siteName?: string;
}

export function EmailExampleWrapper({ children, siteName }: Props) {
  return (
    <div className="bg-main-backgroundFooter border border-white rounded-3xl text-center m-4 p-4 text-white">
      <img
        className="flex m-auto"
        width={64}
        height={64}
        src={logoImg}
        alt="logo"
      />
      <div className="p-4">
        <p className="text-main-letters">
          Site changed:{' '}
          <span className="underline text-white">
            {siteName !== undefined ? siteName : 'my-cool-site'}
          </span>
        </p>
        <div className="text-left text-xs">
          <p>
            <span className="text-scraper-added text-base mr-1">&#9679;</span>
            This is newly added text
          </p>
          <p>
            <span className="text-scraper-removed text-base mr-1">&#9679;</span>
            This text is now removed
          </p>
          <p>
            <span className="text-scraper-changed text-base mr-1">&#9679;</span>
            <span>This text was modified</span>
          </p>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
}
