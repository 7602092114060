import React from 'react';
import SingleRow from './SingleRow';
import { SiteData } from '../types';
import { Tooltip } from 'react-tooltip';

interface Props {
  sites: Array<SiteData>;
  onDelete: Function;
  onEdit: Function;
}

export default function Overview(props: Props) {
  return (
    <div className="hidden sm:block bg-white shadow-md rounded my-6 overflow-x-auto">
      <table className="min-w-max w-full table-auto">
        <thead>
          <tr className="bg-gray-200 text-gray-600 text-sm leading-normal">
            <th className="py-3 px-6 text-left">Site Name</th>
            <th className="py-3 px-6 text-center">Status</th>
            <th className="py-3 px-6 text-center">Last Access</th>
            <th className="py-3 px-6 text-center">Actions</th>
          </tr>
        </thead>
        <tbody className="text-gray-600 text-sm font-light">
          {props.sites.map((site) => (
            <SingleRow
              site={site}
              onDelete={props.onDelete}
              onEdit={props.onEdit}
              key={site.id}
            />
          ))}
          {/* 
            Done  this way because react-tooltip library is clunky, and it just creates bunch of toolbars that each gets triggered on each hover, since dynamic classes work kinda wonky
            So, by doing this here, we've effectively centralized all toolbars in one place, and we are using just these 5 for every site we have
          */}
          <Tooltip
            anchorSelect=".tooltip-edit"
            place="top"
            className="bg-main-backgroundFooter"
          >
            Edit
          </Tooltip>
          <Tooltip
            anchorSelect=".tooltip-delete"
            place="top"
            className="bg-main-backgroundFooter"
          >
            Delete
          </Tooltip>
          <Tooltip
            anchorSelect=".toolbar-site-pending"
            place="top"
            className="bg-main-backgroundFooter"
          >
            we haven&apos;t accessed the site yet
          </Tooltip>
          <Tooltip
            anchorSelect=".toolbar-site-active"
            place="top"
            className="bg-main-backgroundFooter"
          >
            the site has been accessed
          </Tooltip>
          <Tooltip
            anchorSelect=".toolbar-site-down"
            place="top"
            className="bg-main-backgroundFooter"
          >
            the site can&apos;t be accessed
          </Tooltip>
        </tbody>
      </table>
    </div>
  );
}
