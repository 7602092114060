import { createContext, useContext } from 'react';

interface ContextProps {
  // eslint-disable-next-line no-unused-vars
  loginUser: (accessToken: string) => void;
  // eslint-disable-next-line no-unused-vars
  logoutUser: (navigatePath: string, shouldHaveNext: boolean) => void;
}

export const AuthContext = createContext<ContextProps | undefined>(undefined);

export const AuthProvider = AuthContext.Provider;

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthContextProvider');
  }

  return context;
};
