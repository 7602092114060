import toast from 'react-hot-toast';
import { SESSION_EXPIRED } from '../errors';
import { determineServerError } from '../errors/determineServerError';
import { useAuth } from '../context/AuthContext';

type ApiCallResponse = {
  data: any;
  error: string | undefined;
};

export default function useApiCall(
  setWaitingForServerResponse: (_: boolean) => void,
) {
  const auth = useAuth();

  return async (
    apiRequest: any,
    successMessage: string | undefined,
  ): Promise<ApiCallResponse> => {
    setWaitingForServerResponse(true);
    const response = await apiRequest;

    if (!response.ok && response.status === 422) {
      toast.error(SESSION_EXPIRED);
      setWaitingForServerResponse(false);
      auth.logoutUser('/login', true);
      return {
        data: undefined,
        error: SESSION_EXPIRED,
      };
    }

    if (!response.ok) {
      const error = determineServerError(response.data);
      setWaitingForServerResponse(false);
      toast.error(error);
      return {
        data: undefined,
        error: error,
      };
    }

    setWaitingForServerResponse(false);
    if (successMessage) {
      toast.success(successMessage);
    }
    return {
      data: response.data,
      error: undefined,
    };
  };
}
