import React from 'react';
import { toast } from 'react-hot-toast';
import { Navigate, useLocation } from 'react-router-dom';
import * as auth from '../../auth';
import { SESSION_EXPIRED } from '../../errors';

export default function PrivateRoute({ children }) {
  const location = useLocation();
  const nextQueryParam = `?next=${location.pathname}`;

  if (auth.isAuthenticated()) {
    return children;
  }

  toast.error(SESSION_EXPIRED);
  return <Navigate to={`/login${nextQueryParam}`} />;
}
