import React from 'react';
import Actions from './Actions';
import { SiteData } from '../types';
import {
  determineCompleteUrl,
  determineLastAccessDate,
  DetermineSiteStatus,
} from './utils';
import { BiLinkExternal } from 'react-icons/bi';

interface Props {
  site: SiteData;
  onDelete: Function;
  onEdit: Function;
}
export default function SingleRow(props: Props) {
  return (
    <tr className="border-b border-gray-200 hover:bg-gray-100">
      <td className="py-3 px-6 text-left hover:underline">
        <div className="flex items-center">
          <a
            href={determineCompleteUrl(props.site.siteUrl)}
            target="blank"
            className="flex"
          >
            {props.site.siteName}
            <BiLinkExternal size={14} className="ml-0.5" />
          </a>
        </div>
      </td>
      <td className="py-3 px-6 text-center">
        <DetermineSiteStatus site={props.site} />
      </td>
      <td className="py-3 px-6 text-center">
        {determineLastAccessDate(props.site.lastActiveDate)}
      </td>
      <td className="py-3 px-6 text-center">
        <Actions
          onDelete={props.onDelete}
          onEdit={props.onEdit}
          siteId={props.site.id}
        />
      </td>
    </tr>
  );
}
