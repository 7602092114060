import * as Yup from 'yup';
import * as errors from '../../errors';

export const signUpValidationSchema = Yup.object({
  email: Yup.string()
    .email(errors.INVALID_EMAIL_FORMAT)
    .required(errors.REQUIRED_FIELD),
  password: Yup.string()
    .min(8, errors.INVALID_PASSWORD_FORMAT)
    .max(30, errors.INVALID_PASSWORD_FORMAT)
    .required(errors.REQUIRED_FIELD),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], "Password's do not match")
    .required(errors.REQUIRED_FIELD),
}).defined();
