import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as api from '../../api';
import HelmetComponent from '../../components/HelmetComponent';
import LoadingBar from '../../components/LoadingBar';
import SitesOverview from './SitesOverview';
import { SiteFromDB, TrackedSites } from '../../types';
import { OverviewResponse } from '../../api/types';
import { useNavigate } from 'react-router';
import { AiOutlinePlus } from 'react-icons/ai';
import { MdRefresh } from 'react-icons/md';
import useApiCall from '../../hooks/UseApiCall';
import { Tooltip } from 'react-tooltip';

export default function OverviewPage() {
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(true);
  const [trackedSites, setTrackedSites] = useState<TrackedSites>([]);

  const navigate = useNavigate();
  const apiCall = useApiCall(setWaitingForServerResponse);

  useEffect(() => {
    (async () => {
      await displayTrackedSites();
    })();
  }, []);

  const displayTrackedSites = async () => {
    const data = await getExistingSites();
    if (data) {
      setTrackedSites(data);
    }
  };

  const onRemoveSite = async (id: string) => {
    const siteForDeletion = trackedSites.filter(
      (item) => item && item.id == id,
    )[0];
    const remainingSites = trackedSites.filter(
      (item) => item && item.id !== id,
    );

    const response = await apiCall(
      api.deleteSite(siteForDeletion),
      'Site removed successfully.',
    );
    if (response.error) {
      return;
    }
    setTrackedSites(remainingSites);
  };

  const onEditSite = async (id: string) => {
    const targetSite = findTargetSiteById(id);
    navigate('/edit_site', { state: targetSite });
  };

  const findTargetSiteById = (id: string) => {
    const targetSite = trackedSites.find((site) => site.id === id);
    return targetSite;
  };

  const getExistingSites = async () => {
    const response = await apiCall(api.getSiteData(), undefined);

    if (response.error) {
      return;
    }

    const responseData = response.data as OverviewResponse;

    const sitesList: Array<SiteFromDB> = responseData.sites;
    if (!sitesList) {
      return [];
    }
    const existingSitesAddSiteBoxes = sitesList.map((site) => {
      return {
        id: uuidv4(),
        siteUrl: site.url,
        siteName: site.name,
        isActive: site.is_active,
        keywords: site.keywords,
        lastActiveDate: site.last_active_date,
      };
    });
    return existingSitesAddSiteBoxes;
  };

  const displayAddNewSiteButton = () => {
    if (waitingForServerResponse) {
      return;
    }
    return (
      <button
        className="btn btn-primary btn-wide btn-md rounded-xl text-sm flex m-auto"
        onClick={() => navigate('/add_new_site')}
      >
        <AiOutlinePlus className="text-center mr-2 inline-block" size={16} />
        <span className="inline-block">Add new site</span>
      </button>
    );
  };

  return (
    <div className="text-center flex lg:min-h-screen">
      <HelmetComponent
        title="Home | Notify Me"
        description="This is were all the tracked sites are displayed."
        canonicalLink="/overview"
      />

      <div className="w-full mx-auto p-6">
        <p className="nm-default-page-heading">Overview</p>
        <p className="float-right -mt-6">
          <MdRefresh
            className="text-center hover:cursor-pointer hover:text-main-letters refresh-button"
            size={20}
            onClick={displayTrackedSites}
          />
          <Tooltip
            anchorSelect=".refresh-button"
            place="bottom"
            className="bg-main-backgroundFooter"
          >
            Refresh site info
          </Tooltip>
        </p>
        <div className="mt-6">{displayAddNewSiteButton()}</div>

        <div className="mt-2">
          <LoadingBar isLoading={waitingForServerResponse} />
        </div>

        <div>
          <SitesOverview
            sites={trackedSites}
            onDelete={onRemoveSite}
            onEdit={onEditSite}
          />
        </div>
      </div>
    </div>
  );
}
