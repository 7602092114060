import React from 'react';
import HelmetComponent from '../../../components/HelmetComponent';
import { useNavigate } from 'react-router-dom';
import canyonImage from './img/canyonBikeSales.png';
import { canyonBikesPath } from '../paths';

export default function CanyonBikeSales() {
  const navigate = useNavigate();

  return (
    <div className="max-w-7xl m-auto p-8">
      <HelmetComponent
        title="Symplifying the Canyon Bike Sales Hunt: A Practical Guide to Tracking"
        description="Notify Me allows you to effortlessly monitor canyon bike sales, eliminating the need for manual checks."
        canonicalLink={`/blog/${canyonBikesPath}`}
        imageSrc={canyonImage}
        type="article"
      />
      <div className="">
        <div className="max-w-2xl m-auto">
          <h1 className="nm-default-page-heading">
            Symplifying the Canyon Bike Sales Hunt:
            <br /> A Practical Guide to Tracking
          </h1>
          <div className="m-auto mt-8 text-left">
            <p>
              {
                "Canyon bikes are famous for their price-to-quality ratio, since they offer really good bikes for significantly less money than most bike shops, because you are buying directly from the manufacturer. But, catching canyon bike sales is not easy, re-stocks are rare and most often they happen at random times. So, getting one is not an easy task. Let's explore a more efficient way to keep tabs on Canyon bike availability without the hassle."
              }
            </p>
          </div>
          <div>
            <img
              src={canyonImage}
              alt="Canyon Bike"
              className="mt-8 w-full md:max-w-2xl m-auto"
            />
          </div>

          <div className="text-left m-auto mt-8">
            <h2 className="text-main-letters text-2xl font-heading">
              How to know when canyon bikes are back in stock?
            </h2>
            <p className="mt-4">
              Most obvious way would be to go on official canyon bikes site and
              try to catch bike you want to buy. However, checking for bikes
              this way is tiresome and takes up to much time. And, as we said,
              re-stocks are not that often. So, if you go down this route,
              you’ll most likely spend too much time just refreshing the page
              and checking if something new has come up. This is both tedious
              and time consuming.
            </p>
          </div>
          <div className="text-left m-auto mt-8">
            <h2 className="text-main-letters text-2xl font-heading">
              A Practical Solution: Using Notify Me to track canyon bike sales
            </h2>
            <p className="mt-4">
              Since growing number of our users are specifically interested in
              tracking canyon bikes sales, we tailored our algorithms so we
              could improve our tracking.
            </p>
            <div className="mt-4">
              <h2 className="text-main-letters text-2xl font-heading">
                How it works?
              </h2>
              <ul className="list-decimal list-inside px-2">
                <li className="mt-4">
                  <b>Tailored tracking</b>: We are tracking only the crucial
                  info regarding to price and availability of the specific model
                  you are interested in, so you won&apos;t get spammed when
                  something irrelevant changes
                </li>
                <li className="mt-4">
                  <b>Simple setup</b>: Provide us with the URL of the Canyon
                  bike page you want to monitor, and we&apos;ll handle the rest.
                  Our algorithms work quietly in the background to keep you
                  informed about any updates related to your chosen bike.
                </li>
              </ul>
            </div>
          </div>
          <div className="text-left m-auto mt-8">
            <h2 className="text-main-letters text-2xl font-heading">
              Why you should use Notify Me?
            </h2>
            <ul className="list-disc list-inside px-2">
              <li className="mt-4">
                <b>Increase Your Chances</b>: Be the first to know about the
                restocks. With Notify Me, you become a strategic shopper, not
                just a hopeful buyer.
              </li>
              <li className="mt-4">
                <b>Time Efficiency</b>: Say goodbye to constant page refreshing.
                Instead, use Notify Me and spend your time on more important
                things.
              </li>
              <li className="mt-4">
                <b>Customized Alerts</b>: Receive notifications through your
                preferred channels - email, discord or telegram and react
                immediately.
              </li>
            </ul>
          </div>

          <div className="mt-4 max-w-2xl m-auto">
            <button
              className="btn btn-primary btn-block"
              onClick={() => navigate('/signup')}
            >
              Sign Up - It&apos;s Free
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
