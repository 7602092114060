import React from 'react';
import * as auth from '../../auth';
import SidebarWrapper from '../SidebarWrapper';

export default function SidebarIfLoggedIn({ children }) {
  if (!auth.isAuthenticated()) {
    return children;
  }

  return <SidebarWrapper>{children}</SidebarWrapper>;
}
