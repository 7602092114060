/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import HelmetComponent from '../../components/HelmetComponent';
import { useFormik } from 'formik';
import { affiliateValidationSchema } from './validation';
import toast from 'react-hot-toast';
import useApiCall from '../../hooks/UseApiCall';
import * as api from '../../api';
import { DEFAULT_ERROR } from '../../errors/determineServerError';
import LoadingBar from '../../components/LoadingBar';

export default function AffiliateProgram() {
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(false);
  const apiCall = useApiCall(setWaitingForServerResponse);

  const formik1 = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: affiliateValidationSchema,
    onSubmit: async (values) => {
      onSubmit(values.email);
    },
  });

  const formik2 = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: affiliateValidationSchema,
    onSubmit: async (values) => {
      onSubmit(values.email);
    },
  });

  const onSubmit = async (email: string) => {
    setWaitingForServerResponse(true);
    await apiCall(
      await api.sendAffiliate(email),
      'Thank you for your interest in our affiliate program. We will contact you soon.',
    );
    setWaitingForServerResponse(false);
    return;
  };

  return (
    <div className="bg-main-test1">
      <HelmetComponent
        title="Affiliate Program | Notify Me"
        description="Join the Notify Me affiliate program to become our partner. Spread the word about simple website monitoring tool and earn money with us."
        canonicalLink="/earn"
      />

      <div className="m-auto max-w-4xl lg:max-w-5xl text-main-background">
        <div>
          <div className="mb-8">
            <h1 className="text-center text-4xl lg:text-5xl xl:text-6xl font-heading p-4">
              Earn <span className="underline">$299 per month*</span>
              <br /> promoting simple website monitoring and tracking tool.
            </h1>
            <p className="text-lg xl:text-2xl text-center mt-4">
              For every paid client you refer, you will receive 30% of the
              reccuring comission.
              <br /> <span className="font-bold">Forever.</span>
            </p>
            <div>
              <form
                onSubmit={formik1.handleSubmit}
                className="mt-4 text-center"
              >
                <div className="">
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    className="input input-bordered w-full max-w-xs outline-none mr-2"
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                  />
                  {formik1.touched.email && formik1.errors.email ? (
                    <p className="text-xs text-error p-1 block max-w-xs m-auto text-left">
                      {formik1.errors.email}
                    </p>
                  ) : (
                    <p className="text-xs text-error p-1 max-w-xs m-auto text-left block  ">
                      &nbsp;
                    </p>
                  )}
                </div>
                <div className="mb-2">
                  <LoadingBar isLoading={waitingForServerResponse} />
                </div>
                <button
                  className="btn btn-primary m-auto"
                  disabled={formik1.errors.email ? true : false}
                >
                  Get Started
                </button>
              </form>
            </div>
            <p className="text-sm text-center mt-4">
              *For 100 people who purchased the &apos;Standard&apos; plan
            </p>
          </div>
          <div className="p-4">
            <div className="">
              <p className="text-center text-4xl lg:text-5xl xl:text-6xl font-heading p-4">
                How it works?
              </p>
              <p className="text-sm xl:text-xl text-center">
                You will receive a unique link that you can share with your
                audience. When someone signs up using your link, you will
                receive a <span className="font-bold">30% commission</span> from
                every payment they make.
              </p>
            </div>

            <div className="m-auto md:grid md:gap-24 md:grid-cols-3 text-center max-w-4xl mt-4">
              <div className="mt-4 p-8 bg-main-background rounded-xl text-white max-w-xs m-auto">
                <p className="font-heading text-main-letters text-3xl">Share</p>
                <p className="mt-2">
                  Share your unique link with your audience.
                </p>
              </div>
              <div className="mt-4 p-8 bg-main-background rounded-xl text-white max-w-xs m-auto">
                <p className="font-heading text-main-letters text-3xl">Earn</p>
                <p className="mt-2">Earn 30% commission from every payment.</p>
              </div>
              <div className="mt-4 p-8 bg-main-background rounded-xl text-white max-w-xs m-auto">
                <p className="font-heading text-main-letters text-3xl">Track</p>
                <p className="mt-2">Track your earnings in the dashboard.</p>
              </div>
            </div>
          </div>
          <div className="">
            <p className="text-center text-3xl lg:text-5xl xl:text-6xl font-heading p-4 mt-8">
              Sounds Interesting?
            </p>
            <p className="text-sm xl:text-xl text-center">
              Sign up for the Notify Me affiliate program and start earning
              money today.
            </p>
            <div className="pb-8">
              <form
                onSubmit={formik2.handleSubmit}
                className="mt-4 text-center"
              >
                <div className="">
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    className="input input-bordered w-full max-w-xs outline-none mr-2"
                    onChange={formik2.handleChange}
                    onBlur={formik2.handleBlur}
                  />
                  {formik2.touched.email && formik2.errors.email ? (
                    <p className="text-xs text-error p-1 block max-w-xs m-auto text-left">
                      {formik2.errors.email}
                    </p>
                  ) : (
                    <p className="text-xs text-error p-1 max-w-xs m-auto text-left block  ">
                      &nbsp;
                    </p>
                  )}
                </div>
                <div className="mb-2">
                  <LoadingBar isLoading={waitingForServerResponse} />
                </div>
                <button
                  className="btn btn-primary m-auto"
                  disabled={formik2.errors.email ? true : false}
                >
                  Get Started
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
