import React, { MouseEventHandler } from 'react';
interface BaseModalProps {
  id: string;
  heading: string;
  text: JSX.Element;
  onConfirm: MouseEventHandler<HTMLButtonElement>;
}

export function BaseModal(props: BaseModalProps) {
  return (
    <dialog id={props.id} className="modal max-w-sm m-auto">
      <div className="modal-box bg-main-backgroundFooter border-2 border-main-letters rounded-xl">
        <h3 className="font-bold text-lg">{props.heading}</h3>
        <p className="py-4 text-sm">{props.text}</p>
        <div className="flex">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-outline btn-primary mr-auto text-white">
              Close
            </button>
          </form>
          <button className="btn btn-success ml-auto" onClick={props.onConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </dialog>
  );
}
