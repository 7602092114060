import React from 'react';
import NavbarLoggedOut from './NavbarLoggedOut';
import NavbarLoggedIn from './NavbarLoggedIn';
import * as auth from '../../auth';

export default function Navbar() {
  return (
    <div>
      {auth.isAuthenticated() ? <NavbarLoggedIn /> : <NavbarLoggedOut />}
    </div>
  );
}
