import React from 'react';
import HelmetComponent from '../../components/HelmetComponent';
import UseCaseSection from './UseCaseSection';
import UserTestiomonials from './UserTestiomonials';
import MainSection from './MainSection';
import TestSection from './EasyToUseWebsiteMonitoringTool';
import TrackSitesQuicklyAndEasily from './TrackSitesQuicklyAndEasily';
import AllInOnePlace from './AllInOnePlace';
import IntuitiveNotifications from './IntuitiveNotifications';
import ReadyToStartMonitoring from './ReadyToStartMonitoring';
import FAQSection from './FAQSection';
import { PlansLoggedOut } from '../Pricing/PlansLoggedOut';

export default function LandingPage() {
  return (
    <div>
      <HelmetComponent
        title="Notify Me | Website change monitoring, tracking and alerts"
        description="Simple, fast website monitoring. Get alerts on email, telegram or discord. Free to start, no credit card needed."
        canonicalLink=""
        keywords="page change notification, webpage update notificataion, get alerts when a web page changes, web page change notification, page update alert"
      />
      <div>
        <MainSection />
      </div>
      <div className="">
        <TestSection />
      </div>
      <div className="bg-main-test1">
        <TrackSitesQuicklyAndEasily />
      </div>
      <div className="">
        <AllInOnePlace />
      </div>
      <div className="bg-main-test1">
        <IntuitiveNotifications />
      </div>
      <div className="">
        <UserTestiomonials />
      </div>
      <div className="bg-main-test1">
        <UseCaseSection />
      </div>
      <div className="bg-main-letters">
        <ReadyToStartMonitoring />
      </div>
      <div className="">
        <PlansLoggedOut />
      </div>
      <div className="bg-main-test1">
        <FAQSection />
      </div>
    </div>
  );
}
