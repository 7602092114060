import React from 'react';
import { SiteData } from '../types';

export const determineCompleteUrl = (url: string) => {
  const regex = RegExp('http(s){0,1}://');
  if (!regex.test(url)) {
    return `http://${url}`;
  }
  return url;
};

interface Props {
  site: SiteData;
}

export const DetermineSiteStatus = ({ site }: Props) => {
  // // TODO refactor this after migration
  // return (
  //   <div>
  //     <span
  //       className={
  //         'bg-green-600 py-1 px-3 rounded-full text-xs text-white text-center ml-auto toolbar-site-active'
  //       }
  //     >
  //       Active
  //     </span>
  //   </div>
  // );

  let status = '';
  let color = '';
  if (site.isActive == undefined) {
    status = 'Pending';
    color = 'bg-yellow-600';
  } else if (site.isActive) {
    status = 'Active';
    color = 'bg-green-600';
  } else {
    status = 'Down';
    color = 'bg-red-600';
  }

  if (status == 'Pending') {
    return (
      <div>
        <span
          className={
            color +
            ' py-1 px-3 rounded-full text-xs text-white text-center ml-auto toolbar-site-pending'
          }
        >
          {status}
        </span>
      </div>
    );
  }

  if (status == 'Active') {
    return (
      <div>
        <span
          className={
            color +
            ' py-1 px-3 rounded-full text-xs text-white text-center ml-auto toolbar-site-active'
          }
        >
          {status}
        </span>
      </div>
    );
  }

  if (status == 'Down') {
    return (
      <div>
        <span
          className={
            color +
            ' py-1 px-3 rounded-full text-xs text-white text-center ml-auto toolber-site-down'
          }
        >
          {status}
        </span>
      </div>
    );
  }

  return (
    <span className={color + ' py-1 px-3 rounded-full text-xs text-white'}>
      {status}
    </span>
  );
};

export const determineLastAccessDate = (lastAccessDate: string | undefined) => {
  if (lastAccessDate == undefined) {
    return 'No Data';
  }
  return new Date(Date.parse(lastAccessDate)).toLocaleString('en-us', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};
